// Create a context
import React, { createContext, useContext, useState ,ReactNode } from 'react';


type GlobalStateContextType = {
  LogInUserName: string;
  setLogInUserName: Function;
  LogInUserImage: string;
  setLogInUserImage: Function;
  LogInUserRole: string;
  setLogInUserRole: Function;
  ManageAdmin: boolean;
  setManageAdmin: Function;
  NotificationObject: {
    ShowPopup: boolean,
    Heading: string,
    Message: string,
  };
  setNotificationObject: Function;
  ConfirmationModalObject: {
    ShowPopup: boolean;
    Heading: string;
    Message: string;
    ID: number;
    SelectedID: number;
    OtherID:number;
  };
  setConfirmationModalObject: Function;
};

const GlobalStateContext = createContext<GlobalStateContextType | undefined>(undefined);

export const GlobalStateProvider = ({ children }:any) => {
  const [LogInUserName, setLogInUserName] = useState<string>("");
  const [LogInUserImage, setLogInUserImage] = useState<string>("");
  const [LogInUserRole, setLogInUserRole] = useState<string>("");
  const [ManageAdmin, setManageAdmin] = useState<boolean>(false);
  const [NotificationObject, setNotificationObject] = useState({
    ShowPopup: false,
    Heading: "",
    Message: "",
  });
  const [ConfirmationModalObject, setConfirmationModalObject] = useState({
    ShowPopup: false,
    Heading: "",
    Message: "",
    ID: 0,
    SelectedID: 0,
    OtherID:0,
  });
  return (
    <GlobalStateContext.Provider value={{ LogInUserName, setLogInUserName , LogInUserImage, setLogInUserImage , LogInUserRole, setLogInUserRole,ManageAdmin, setManageAdmin,NotificationObject, setNotificationObject,
      ConfirmationModalObject,
      setConfirmationModalObject,}}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error('useGlobalState must be used within a GlobalStateProvider');
  }
  return context;
};
