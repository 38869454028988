import { useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import AppContext from "../Context/Context";
import { useGlobalState } from "../Context/GlobalStateContext";
export default function NotRequireAuth({ children }: any) {
  const location = useLocation();
  let completeURL = location.pathname + location.search;
  const [IsUserLoggedIn, setIsUserLoggedIn] = useState(true);
  const [OkToGo, setOkToGo] = useState(false);
  const globalState = useGlobalState();

  useEffect(() => {
    const CheckIsUserLoggedIn = async () => {
      const isLoggedIn = await AppContext.IsUserLoggedIn();
      if (isLoggedIn === true) {
        var token = AppContext.GetCookie("Token");
        if (token && token !== "") {
          SetUserInfo(token);
        }
        window.location.href = "/employeeview";
        return true;
      } else {
        return false;
      }
    };

    const SetUserInfo = (token: any) => {
      if (token && token !== "") var UserInfo: any = jwt_decode(token);
      if (UserInfo && UserInfo !== "") {
        globalState.setLogInUserName(UserInfo.username);
        globalState.setLogInUserImage(UserInfo.userimage);
        globalState.setLogInUserRole(UserInfo.role);
      }
    };

    (async () => {
      if ((await CheckIsUserLoggedIn()) === true) {
        // let havePermission = await AppContext.CheckAccessAndSettingsNDT(
        //   completeURL
        // );
        // if (havePermission) {
        //   setIsUserLoggedIn(true);
        // } else {
        //   setIsUserLoggedIn(false);
        // }


        setIsUserLoggedIn(true);

      } else {
        setIsUserLoggedIn(false);
      }
      setOkToGo(true);
    })();

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

  let ReturnData = "" as any;
  if (IsUserLoggedIn === true && OkToGo) {
    window.location.href = "/employeeview";
  } else if (IsUserLoggedIn === false && OkToGo) {
    ReturnData = children;
  }
  return ReturnData;
}
