import { createContext } from "react";
import jwt_decode from "jwt-decode";
import axios from "axios";
import axiosAuth from "../Axios/Interceptors";

const AppContext = createContext(null) as any;

AppContext.LogInUserName = "";
AppContext.GoogleRating = 4.5;
AppContext.ClientType = 0;
AppContext.BusinessName = "";
AppContext.FirstName = "";
AppContext.LastName = "";
AppContext.ClientEmail = "";
AppContext.ClientPhoneNumber = "";

// //Live
AppContext.APIURL = "https://api.finestvan.co.uk/";
AppContext.FilesAPI = "https://api.finestvan.co.uk/";
AppContext.Domain = "domain=.finestvan.co.uk";
AppContext.SiteURL = "https://appadmin.finestvan.co.uk/";

// //Dev
// AppContext.APIURL = "https://apidev.finestvan.co.uk/";
// AppContext.FilesAPI = "https://apidev.finestvan.co.uk/";
// AppContext.Domain = "domain=.currentdomain.co.uk";
// AppContext.SiteURL = "https://charlie.currentdomain.co.uk/";

// //Local Host
// AppContext.APIURL = "https://localhost:7021/";
// AppContext.FilesAPI = "https://localhost:7021/";
//AppContext.Domain = "domain=localhost";
// AppContext.SiteURL = "localhost:3000";

AppContext.BlogPostPageURL = "blog";

AppContext.FileUploadObjectTypeQuote = 1;
AppContext.FileUploadObjectTypeContactUs = 2;

AppContext.AddressApiKey = "-BeLNIcl0EaCtL3ULbdZJA32796";

AppContext.quoteStep = {
  PickUpLocation: 1,
  AdditionalStop: 2,
  DropoffLocation: 3,
  ServiceRequired: 4,
  VanSelection: 5,
};

AppContext.pickupDetails = { PostCode: "", Street: "", City: "" };
AppContext.dropoffDetails = { PostCode: "", Street: "", City: "" };

AppContext.JobID = "";
AppContext.MNVJobID = 0;
AppContext.MNVQuoteID = 0;
AppContext.MNVQuoteIDBKTH = 0;

AppContext.yearsList = [
  { ID: 2023, Name: "2023" },
  { ID: 2024, Name: "2024" },
  { ID: 2025, Name: "2025" },
  { ID: 2026, Name: "2026" },
];

AppContext.monthsList = [
  { ID: 1, Name: "Jan" },
  { ID: 2, Name: "Feb" },
  { ID: 3, Name: "Mar" },
  { ID: 4, Name: "Apr" },
  { ID: 5, Name: "May" },
  { ID: 6, Name: "Jun" },
  { ID: 7, Name: "Jul" },
  { ID: 8, Name: "Aug" },
  { ID: 9, Name: "Sep" },
  { ID: 10, Name: "Oct" },
  { ID: 11, Name: "Nov" },
  { ID: 12, Name: "Dec" },
];

AppContext.floorsList = [
  { PID: -2, Name: "Basement" },
  { PID: -1, Name: "Ground Floor" },
  { PID: 1, Name: "1st Floor" },
  { PID: 2, Name: "2nd Floor" },
  { PID: 3, Name: "3rd Floor" },
  { PID: 4, Name: "4th Floor" },
  { PID: 5, Name: "5th Floor" },
  { PID: 6, Name: "6th Floor" },
  { PID: 7, Name: "7th Floor" },
  { PID: 8, Name: "8th Floor" },
  { PID: 9, Name: "9th Floor" },
  { PID: 10, Name: "10th Floor" },
  { PID: 11, Name: "11th Floor" },
  { PID: 12, Name: "12th Floor" },
  { PID: 13, Name: "13th Floor" },
  { PID: 14, Name: "14th Floor" },
  { PID: 15, Name: "15th Floor" },
  { PID: 16, Name: "16th Floor" },
  { PID: 17, Name: "17th Floor" },
  { PID: 18, Name: "18th Floor" },
  { PID: 19, Name: "19th Floor" },
  { PID: 20, Name: "20th Floor" },
  { PID: 21, Name: "20th Floor+" },
];

AppContext.getMNVJobID = () => {
  try {
    let JobID = Number(AppContext.JobID);
    let MNVJobID = JobID - 250000;
    if (MNVJobID < 0) MNVJobID = 0;
    return MNVJobID;
  } catch {
    return 0;
  }
};

AppContext.SetCookie = (cname: any, cvalue: any, exmins: any) => {
  const d = new Date();
  if (exmins == 0) {
    d.setTime(0);
  } else {
    d.setTime(d.getTime() + exmins * 60 * 1000);
  }
  let expires = "expires=" + d;
  document.cookie =
    cname + "=" + cvalue + ";" + expires + ";" + AppContext.Domain + ";path=/";
};

AppContext.SetGeneralCookie = (cname: any, cvalue: any, exmins: any) => {
  const d = new Date();
  if (exmins == 0) {
    d.setTime(0);
  } else {
    d.setTime(d.getTime() + exmins * 60 * 1000);
  }
  let expires = "expires=" + d;
  document.cookie = cname + "=" + cvalue + ";" + expires + ";" + ";path=/";
};

AppContext.GetCookie = (cname: any) => {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

AppContext.GetInitials = (fullName: any) => {
  let initials = "PT";
  if (fullName !== "") {
    const allNames = fullName.trim().split(" ");
    initials = allNames.reduce((acc: any, curr: any, index: any) => {
      if (index === 0 || index === allNames.length - 1) {
        acc = `${acc}${curr.charAt(0).toUpperCase()}`;
      }
      return acc;
    }, "");
  }
  return initials;
};

AppContext.GetUserIdFromUserInfo = () => {
  var token = AppContext.GetCookie("Token");
  if (token && token !== "") var UserInfo = jwt_decode(token) as any;
  if (UserInfo && UserInfo !== "") {
    return UserInfo.user;
  } else {
    return "";
  }
};

AppContext.GetJWTAndDecode = () => {
  var token = AppContext.GetCookie("Token");
  if (token && token !== "") var UserInfo = jwt_decode(token) as any;
  if (UserInfo && UserInfo !== "") {
    AppContext.LogInUserName = UserInfo.username;
    //Router.push("home");
  } else {
    //Router.push("login");
  }
};

AppContext.MoveToHomeScreen = () => {
  var token = AppContext.GetCookie("Token");
  if (token && token !== "") var UserInfo = jwt_decode(token) as any;
  if (UserInfo && UserInfo !== "") {
    AppContext.LogInUserName = UserInfo.username;
    //Router.push("/home");
  } else {
    //Router.push("login");
  }
};

AppContext.CheckUserLogIn = () => {
  var token = AppContext.GetCookie("Token");
  if (token && token !== "") var UserInfo = jwt_decode(token) as any;
  if (UserInfo && UserInfo !== "") {
    AppContext.LogInUserName = UserInfo.username;
  } else {
    //Router.push("/login");
  }
};

AppContext.IsUserLoggedInold = () => {
  var token = AppContext.GetCookie("Token");
  if (token && token !== "") var UserInfo = jwt_decode(token);
  if (UserInfo && UserInfo !== "") {
    return true;
  } else {
    return false;
  }
};

AppContext.IsUserLoggedIn = async (p_WantRedirections = false) => {
  var token = AppContext.GetCookie("Token");
  if (token && token !== "" && !AppContext.IsJwtExpired(token)) {
    AppContext.SetUserName();
    return true;
  } else {
    await AppContext.GetNewJWTTokenFromRefreshToken(p_WantRedirections);
    token = AppContext.GetCookie("Token");
    if (token && token !== "" && !AppContext.IsJwtExpired(token)) {
      AppContext.SetUserName();
      return true;
    } else {
      return false;
    }
  }
};

AppContext.IsJwtExpired = (token: any) => {
  if (token && token !== "") {
    var UserInfo = jwt_decode(token) as any;
    let currentDate = new Date();
    if (UserInfo.exp * 1000 < currentDate.getTime()) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

AppContext.SetUserName = () => {
  var token = AppContext.GetCookie("Token");
  if (token && token !== "") var UserInfo: any = jwt_decode(token);
  if (UserInfo && UserInfo !== "") {
    AppContext.LogInUserName = UserInfo.username;
  }
};

AppContext.SetClientDetails = () => {
  if (AppContext.LogInUserName != "") {
    let data: any = {};
    axiosAuth
      .post(AppContext.APIURL + "api/SiteUser/LoadLoginClientDetails", data)
      .then((response) => {
        if (response.data.Status === 1) {
          let result = response.data.Data[0];
          if (result.length > 0) {
            AppContext.ClientType = result[0]["TypeID"];
            AppContext.BusinessName = result[0]["BusinessName"];
            AppContext.FirstName = result[0]["FirstName"];
            AppContext.LastName = result[0]["LastName"];
            AppContext.ClientEmail = result[0]["EmailAddress"];
            AppContext.ClientPhoneNumber = result[0]["Mobile"];
          }
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
};

AppContext.UpdateCampaignStatus = (CampaignStatus: number) => {
  let data: any = {};
  data.cntkn = AppContext.GetCookie("cntkn");
  data.mnvjobid = AppContext.getMNVJobID();
  data.campaignstatus = CampaignStatus;
  axiosAuth
    .post(AppContext.APIURL + "api/PTCampaign/UpdateCampaignStatus", data)
    .then((response) => {
      if (response.data.Status === 1) {
        let result = response.data.Data[0];
      }
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
};

AppContext.GetNewJWTTokenFromRefreshToken = async (p_WantRedirections: any) => {
  var RefreshToken = AppContext.GetCookie("RefreshToken");
  if (
    RefreshToken &&
    RefreshToken !== "" &&
    !AppContext.IsJwtExpired(RefreshToken)
  ) {
    let object: any = {};
    object.token = RefreshToken;
    await axios
      .post(AppContext.APIURL + "api/SiteUser/RefreshToken", await object)
      .then((response) => {
        if (response.data.Status === 1) {
          if (response.data.Data[0].Token !== "") {
            AppContext.SetCookie("Token", response.data.Data[0].Token, 30);
            AppContext.SetUserName();
            window.location.href = "/login";
          } else {
            AppContext.SetCookie("Token", "", 0);
            AppContext.SetCookie("RefreshToken", "", 0);
            if (p_WantRedirections) window.location.href = "/login";
          }
        } else {
          AppContext.SetCookie("Token", "", 0);
          AppContext.SetCookie("RefreshToken", "", 0);
          if (p_WantRedirections) window.location.href = "/login";
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
        AppContext.SetCookie("Token", "", 0);
        AppContext.SetCookie("RefreshToken", "", 0);
        if (p_WantRedirections) window.location.href = "/login";
      });
  } else {
    AppContext.SetCookie("Token", "", 0);
    AppContext.SetCookie("RefreshToken", "", 0);
    if (p_WantRedirections) window.location.href = "/login";
  }
};

AppContext.IsValidEmail = (FieldValue: any) => {
  //"^[^@\s]+@[^@\s]+\.[^@\s]+$"
  if (/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(FieldValue)) {
    return true;
  }
  return false;
};

AppContext.GetTimeDifferenceStringFromDate = (p_date: any) => {
  var l_oDate1 = new Date(p_date);
  l_oDate1 = AppContext.ConvertDateTimeToLocal(l_oDate1);
  var l_oDate2 = new Date();

  var l_decSeconds = AppContext.GetDifferenceBetweenTwoDatesInSeconds(
    l_oDate1,
    l_oDate2
  );
  var l_decMinutes = Math.round(l_decSeconds / 60);
  var l_decHours = Math.round(l_decMinutes / 60);
  var l_decDays = Math.round(l_decHours / 24);

  if (l_decSeconds < 60) return "< 1 minute ago";
  else if (l_decMinutes < 60) return l_decMinutes + " minutes ago";
  else if (l_decHours == 1) return l_decHours + " hour ago";
  else if (l_decHours > 1 && l_decHours < 24) return l_decHours + " hours ago";
  else if (l_decDays == 1) return l_decDays + " day ago";
  else if (l_decDays > 1 && l_decDays < 7) return l_decDays + " days ago";
  else
    return (
      (l_oDate1.getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      l_oDate1.getDate().toString().padStart(2, "0") +
      "/" +
      l_oDate1.getFullYear()
    );
};

AppContext.GetDifferenceBetweenTwoDatesInSeconds = (
  p_oFirstDate: any,
  p_oSecondDate: any
) => {
  var diff = Math.abs(
    new Date(p_oFirstDate).getTime() - new Date(p_oSecondDate).getTime()
  );
  var diffDays = diff / 1000;
  return diffDays;
};

AppContext.ConvertDateTimeToLocal = (date: any) => {
  date = new Date(date);
  var localOffset = date.getTimezoneOffset() * 60000;
  var localTime = date.getTime();
  date = localTime - localOffset;
  date = new Date(date);

  return date;
};

AppContext.PTCheckPasswordValidation = (FieldValue: any) => {
  //var RegExpresson = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  var RegExpresson = /^(?=.*["}{)(|,'?><_~!@#\$%\^&\*^%:;.`/-])/;
  //var l_boolValid = RegExpresson.test(FieldValue);
  var l_boolValid = true;
  var HasWhiteSpace = false;
  var Message = "";
  if (/\s/.test(FieldValue)) {
    // It has any kind of whitespace
    HasWhiteSpace = true;
  }
  if (HasWhiteSpace) {
    l_boolValid = false;
    IsAnyError = true;

    Message =
      "<br /><span class='p-error'>White spaces are not allowed.</span>";
  }
  var IsAnyError = false;
  if (FieldValue.length < 8) {
    Message +=
      "<br /><span class='p-error'>A minimum of 8 characters required.</span>";
    IsAnyError = true;
    l_boolValid = false;
  } else {
    Message +=
      "<br /><span class='p-ok'>A minimum of 8 characters required.</span>";
  }

  if (!/[a-z]/.test(FieldValue)) {
    Message +=
      "<br /><span class='p-error'>At least one lowercase letter is required.</span>";
    IsAnyError = true;
    l_boolValid = false;
  } else {
    Message +=
      "<br /><span class='p-ok'>At least one lowercase letter is required.</span>";
  }

  if (FieldValue == FieldValue.toLowerCase()) {
    Message +=
      "<br /><span class='p-error'>At least one capital letter is required.</span>";
    IsAnyError = true;
    l_boolValid = false;
  } else {
    Message +=
      "<br /><span class='p-ok'>At least one capital letter is required.</span>";
  }

  if (!/\d/.test(FieldValue)) {
    Message +=
      "<br /><span class='p-error'>At least one number is required.</span>";
    IsAnyError = true;
    l_boolValid = false;
  } else {
    Message +=
      "<br /><span class='p-ok'>At least one number is required.</span>";
  }
  // if (/^[a-zA-Z0-9- ]*$/.test(FieldValue) == true) {
  if (!RegExpresson.test(FieldValue)) {
    Message +=
      "<br /><span class='p-error'>At least one special character (e.g. @) is required.</span>";
    IsAnyError = true;
    l_boolValid = false;
  } else {
    Message +=
      "<span class='p-ok'>At least one special character (e.g. @) is required.</span>";
  }
  // }

  return { Valid: l_boolValid, Message: Message };
};

AppContext.GenerateUniqueGUID = () => {
  return (
    "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    }) +
    "-" +
    Date.now()
  );
};

AppContext.GetFileIconToDisplay = (FilePath: any) => {
  var IconURL = FilePath;
  var parts = FilePath.split(".");
  var ext = parts[parts.length - 1];
  switch (ext.toLowerCase()) {
    case "pdf":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/pdficon.jpg";
      break;
    case "docx":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/Word.jpg";
      break;
    case "doc":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/Word.jpg";
      break;
    case "xlsx":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/xls_icon.png";
      break;
    case "xls":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/xls_icon.png";
      break;
    case "pptx":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/ppt.png";
      break;
    case "ppt":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/ppt.png";
      break;
    case "wav":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/audio.png";
      break;
    case "mid":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/audio.png";
      break;
    case "midi":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/audio.png";
      break;
    case "wma":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/audio.png";
      break;
    case "mp3":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/audio.png";
      break;
    case "ogg":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/audio.png";
      break;
    case "rma":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/audio.png";
      break;
    case "au":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/audio.png";
      break;
    case "ea":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/audio.png";
      break;
    case "avi":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/video.png";
      break;
    case "mp4":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/video.png";
      break;
    case "divx":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/video.png";
      break;
    case "wmv":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/video.png";
      break;
    case "mkv":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/video.png";
      break;
    case "flv":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/video.png";
      break;
    case "avi":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/video.png";
      break;
    case "vob":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/video.png";
      break;
    case "ogv":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/video.png";
      break;
    case "wmv":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/video.png";
      break;
    case "m4v":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/video.png";
      break;
    case "mov":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/video.png";
      break;
    case "wmv":
      IconURL = "https://dft8zj8i6wy63.cloudfront.net/defaultimg/video.png";
      break;
    default:
      IconURL = FilePath;
  }
  return IconURL;
};

AppContext.SortJsonData = (JsonData: any, ColumnName: any) => {
  let SortedData = JsonData.sort((a: any, b: any) => {
    if (a[ColumnName] < b[ColumnName]) {
      return -1;
    }
  });

  return SortedData;
};

{
  /* <div className="popup-drop flex-dj">
  <div className="inline-block-d">
    <div className="popup-review popup-plan popup-claim">
      <div className="flex-daj show-767">
        <a className="flex-daj c-purple text-underline">Back</a>
      </div>
      <h3 className="flex-d">Disclaimer</h3>
      <div className="plan-text">
        <span className="flex-d">
          Lorem Ipsum sit dolor amit, Lorem ipsum sit dolor amit, Lorem Ipsum
          sit dolor amit, Lorem ipsum sit dolor amit
        </span>
      </div>
      <div className="btn-popup-close btn-under flex-d">
        <a className="btn-secondary flex-daj">Yes, I Understand</a>
      </div>
    </div>
  </div>
</div> */
}

export default AppContext;
