import React, { useState, useEffect } from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import MobileInput from "../MobileInput/MobileInput";
function Signup() {
  const [showPassword, setShowPassword] = useState(false);
  const [isOkToGo, setIsOkToGo] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [signupObject, setSignupObject] = useState<any>({
    Email: "",
    Password: "",
    ConfirmPassword: "",
    FirstName: "",
    LastName: "",
    Code: "",
    MobileNumber: "+44",
  });
  const [signupValidationObject, setSignupValidationObject] = useState<any>({
    IsShowCodeField: false,
    LoadingState: false,
    EmailAvailabilityCheckLastEmail: "",
    EmailAvailability: "",
    ErrorMessage: "",
    CodeValidationMessage: "",
  });


  function CheckEmailValidationAndAvailability() {
    if (signupObject.Email && signupObject.Email !== "") {
      if (
        signupValidationObject.EmailAvailabilityCheckLastEmail !==
        signupObject.Email
      ) {
        if (AppContext.IsValidEmail(signupObject.Email)) {
          AjaxCheckEmailValidationAndAvailability();
        } else {
          setSignupValidationObject((prevState: any) => ({
            ...prevState,
            EmailAvailability: "Enter valid email.",
          }));
        }
      }
    }
  }

  function AjaxCheckEmailValidationAndAvailability() {
    let object = {} as any;
    object.email = signupObject.Email;
    object.crdsvvl = AppContext.GetCookie("crdsvvl");

    setSignupValidationObject((prevState: any) => ({
      ...prevState,
      EmailAvailabilityCheckLastEmail: signupObject.Email,
    }));

    axiosAuth
      .post(AppContext.APIURL + "api/SiteUser/CheckEmailAvailability", object)
      .then((response) => {
        if (response.data.Status === 1) {
          setSignupValidationObject((prevState: any) => ({
            ...prevState,
            EmailAvailability: "",
          }));
        } else {
          setSignupValidationObject((prevState: any) => ({
            ...prevState,
            EmailAvailability: response.data.Message,
          }));
          setIsOkToGo(false);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  useEffect(() => {
    checkValidation(false);
  }, [signupObject]);

  function checkValidation(takeAction: boolean) {
    if (
      !checkPasswordVaildation() ||
      signupObject.Email === "" ||
      signupObject.Password === "" ||
      signupObject.ConfirmPassword === "" ||
      signupObject.FirstName === "" ||
      signupObject.LastName === "" ||
      signupObject.MobileNumber === "" ||
      signupValidationObject.EmailAvailability !== "" ||
      signupValidationObject.ErrorMessage !== "" ||
      !isMobileNumberOk() ||
      !checkConfirmPasswordVaildation()
    ) {
      if (takeAction) {
        setShowErrorMessage(true);
      }
      setIsOkToGo(false);
    } else {
      setIsOkToGo(true);
      setShowErrorMessage(false);
      if (takeAction) {
        if (
          signupValidationObject.EmailAvailability === "" &&
          signupValidationObject.ErrorMessage === ""
        ) {
          AjaxSendVerification();
        }
      }
    }
  }

  useEffect(() => {
    checkValidationForCode(false);
  }, [signupObject.Code]);

  function checkValidationForCode(takeAction: boolean) {
    if (signupObject.Code === "" && signupObject.Code.length !== 6) {
      if (takeAction) {
        setShowErrorMessage(true);
      }
      setIsOkToGo(false);
    } else {
      setIsOkToGo(true);
      setShowErrorMessage(false);
      if (takeAction) {
        AjaxSignupUser();
      }
    }
  }

  function checkPasswordVaildation() {
    let IsOk = false;
    if (signupObject.Password !== "") {
      var DataPassword = AppContext.PTCheckPasswordValidation(
        signupObject.Password
      );
      if (!DataPassword.Valid) {
        signupValidationObject.ErrorMessage = DataPassword.Message;
        setSignupValidationObject((prevState: any) => ({
          ...prevState,
          ErrorMessage: DataPassword.Message,
        }));
        IsOk = false;
      } else {
        signupValidationObject.ErrorMessage = "";
        setSignupValidationObject((prevState: any) => ({
          ...prevState,
          ErrorMessage: "",
        }));
        IsOk = true;
      }

      return IsOk;
    }
  }

  function checkConfirmPasswordVaildation() {
    let IsOk = false;
    if (checkPasswordVaildation()) {
      if (signupObject.Password !== signupObject.ConfirmPassword) {
        signupValidationObject.ErrorMessage =
          "Password and Confirm Password are not matched.";
        setSignupValidationObject((prevState: any) => ({
          ...prevState,
          ErrorMessage: "Password and Confirm Password are not matched.",
        }));
        IsOk = false;
        setIsOkToGo(false);
      } else {
        signupValidationObject.ErrorMessage = "";
        setSignupValidationObject((prevState: any) => ({
          ...prevState,
          ErrorMessage: "",
        }));
        IsOk = true;
        setIsOkToGo(true);
      }
    }

    return IsOk;
  }

  function AjaxSendVerification() {
    let object = {} as any;
    object.email = signupObject.Email;
    object.crdsvvl = AppContext.GetCookie("crdsvvl");
    setSignupValidationObject((prevState: any) => ({
      ...prevState,
      LoadingState: true,
    }));

    axiosAuth
      .post(
        AppContext.APIURL + "api/SiteUser/SendCodeForSignupViaEmail",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          setSignupValidationObject((prevState: any) => ({
            ...prevState,
            IsShowCodeField: true,
          }));
          setIsOkToGo(false);
        } else {
        }
        setSignupValidationObject((prevState: any) => ({
          ...prevState,
          LoadingState: false,
        }));
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  function AjaxSignupUser() {
    let object = {} as any;
    object.lastname = signupObject.LastName;
    object.email = signupObject.Email;
    object.password = signupObject.Password;
    object.firstname = signupObject.FirstName;
    object.phone = signupObject.MobileNumber;
    object.confirmpassword = signupObject.ConfirmPassword;
    object.code = signupObject.Code;
    object.crdsvvl = AppContext.GetCookie("crdsvvl");
    setSignupValidationObject((prevState: any) => ({
      ...prevState,
      LoadingState: true,
    }));
    axiosAuth
      .post(AppContext.APIURL + "api/SiteUser/GetUserSignedUp", object)
      .then((response) => {
        if (response.data.Status === 1) {
          AppContext.SetCookie("Token", response.data.Data[0].Token, 30);
          if (response.data.Data[0].RefreshToken) {
            AppContext.SetCookie(
              "RefreshToken",
              response.data.Data[0].RefreshToken,
              600
            );
          }
          setSignupValidationObject((prevState: any) => ({
            ...prevState,
            CodeValidationMessage: "",
          }));
          AppContext.MoveToHomeScreen();
        } else {
          setSignupValidationObject((prevState: any) => ({
            ...prevState,
            CodeValidationMessage: response.data.Message,
          }));
          AppContext.SetCookie("Token", "", 0);
        }

        setSignupValidationObject((prevState: any) => ({
          ...prevState,
          LoadingState: false,
        }));
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  const [SelectedMobileNumber, SetSelectedMobileNumber] = useState("");
  useEffect(() => {
    setSignupObject((prevState: any) => ({
      ...prevState,
      MobileNumber: SelectedMobileNumber,
    }));
  }, [SelectedMobileNumber]);

  function isMobileNumberOk(): boolean {
    let IsOk = false;
    let mobileNumber = signupObject.MobileNumber.split("-");
    if (
      mobileNumber &&
      mobileNumber[0] &&
      mobileNumber[1] &&
      mobileNumber[1] !== ""
    ) {
      IsOk = true;
    } else {
      IsOk = false;
    }
    return IsOk;
  }

  return (
    <>

      {signupValidationObject.LoadingState && <LoadingAnimation />}
      <div className="login-fields signup-fields inline-block-d text-center width-100">
        <div className="input-center">
          <h1 className="login-heading">Sign Up</h1>
          {!signupValidationObject.IsShowCodeField ? (
            <>
              <input
                type="text"
                placeholder="First name"
                value={signupObject.FirstName}
                onChange={(e) =>
                  setSignupObject((prevState: any) => ({
                    ...prevState,
                    FirstName: e.target.value,
                  }))
                }
                className="input-field flex-d width-100"
              />
              <div className="flex-d width-100 input-gap"></div>
              <input
                type="text"
                placeholder="Last name"
                value={signupObject.LastName}
                onChange={(e) =>
                  setSignupObject((prevState: any) => ({
                    ...prevState,
                    LastName: e.target.value,
                  }))
                }
                className="input-field flex-d width-100"
              />
              <div className="flex-d width-100 input-gap"></div>
              <input
                type="text"
                placeholder="Email address"
                value={signupObject.Email}
                onChange={(e) =>
                  setSignupObject((prevState: any) => ({
                    ...prevState,
                    Email: e.target.value,
                  }))
                }
                onBlur={() => CheckEmailValidationAndAvailability()}
                className="input-field flex-d width-100"
              />
              <div className="flex-d width-100 input-gap"></div>
              <div
                className={`form-field flex-da ${
                  signupObject.MobileNumberError ? "" : ""
                }`}
              >
                <MobileInput
                  SelectedMobileNumber={SelectedMobileNumber}
                  SetSelectedMobileNumber={SetSelectedMobileNumber}
                />
              </div>
              <div className="flex-d width-100 input-gap"></div>
              <div className="p-relative width-100">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={signupObject.Password}
                  onChange={(e) =>
                    setSignupObject((prevState: any) => ({
                      ...prevState,
                      Password: e.target.value,
                    }))
                  }
                  className="input-field flex-d width-100"
                />
                <a
                  onClick={() => setShowPassword(!showPassword)}
                  className="show-hide-pass"
                >
                  {!showPassword && (
                    <img
                      src="https://dft8zj8i6wy63.cloudfront.net/mnv/images/eye.svg"
                      width="18"
                      height="14"
                      alt="Eye"
                    />
                  )}
                  {showPassword && (
                    <img
                      src="https://dft8zj8i6wy63.cloudfront.net/mnv/images/eye-slash.png"
                      width="18"
                      height="14"
                      alt="Eye-Slash"
                    />
                  )}
                </a>
              </div>
              <div className="flex-d width-100 input-gap"></div>
              <input
                type="password"
                placeholder="Confirm password"
                value={signupObject.ConfirmPassword}
                onChange={(e) =>
                  setSignupObject((prevState: any) => ({
                    ...prevState,
                    ConfirmPassword: e.target.value,
                  }))
                }
                onKeyUp={() => checkConfirmPasswordVaildation()}
                className="input-field flex-d width-100"
              />
              <div
                className="pass-errors"
                dangerouslySetInnerHTML={{
                  __html: signupValidationObject.ErrorMessage,
                }}
              ></div>
            </>
          ) : (
            <>
              <div>
                <input
                  type="text"
                  name="Code"
                  value={signupObject.Code}
                  onChange={(e) =>
                    setSignupObject((prevState: any) => ({
                      ...prevState,
                      Code: e.target.value,
                    }))
                  }
                  className="input-field flex-d width-100"
                />
              </div>
            </>
          )}

          <div className="login-btn flex-dj width-100">
            {!signupValidationObject.IsShowCodeField ? (
              <a
                className={`btn-primary flex-daj  ${
                  !isOkToGo ? "btn-disabled" : ""
                }`}
                onClick={() => checkValidation(true)}
              >
                Sign Up
              </a>
            ) : (
              <a
                className={`btn-primary flex-daj  ${
                  !isOkToGo ? "btn-disabled" : ""
                }`}
                onClick={() => checkValidationForCode(true)}
              >
                Verify Code
              </a>
            )}
          </div>

          {signupValidationObject.EmailAvailability !== "" && (
            <span className="error-message flex-dj">
              {signupValidationObject.EmailAvailability}
            </span>
          )}

          {signupValidationObject.CodeValidationMessage !== "" && (
            <span className="error-message flex-dj">
              {signupValidationObject.CodeValidationMessage}
            </span>
          )}

          {showErrorMessage &&
            signupValidationObject.EmailAvailability === "" &&
            signupValidationObject.ErrorMessage === "" && (
              <span className="error-message flex-dj">
                You need to fill all the information to login.
              </span>
            )}
          <div className="forgot-link flex-dj width-100">
            <a href="/forgotpassword" className="c-purple">
              Forgotten password?
            </a>
          </div>
        </div>
        <span className="txt-register flex-dj">
          If you already have an account, you can login here.
        </span>
        <div className="register-btn flex-dj">
          <a className="btn-secondary flex-daj" href="/login">
            Login
          </a>
        </div>
      </div>
    </>
  );
}

export default Signup;
