import React, { useState, useEffect } from "react";
import AppContext from "../Context/Context";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import axiosAuth from "../Axios/Interceptors";
import moment from "moment";
import { getEndOfMonth, getStartOfMonth } from "../Shared/Utility";
import { useGlobalState } from "../Context/GlobalStateContext";
import ConfirmationModal from "../App/ConfirmationModal";
function MNVMoverShiftData() {
  const globalState = useGlobalState();
  const [isAnimation, setIsAnimation] = useState(false);
  const [isAddNew, setIsAddNew] = useState(0);
  const [isSummaryView, setIsSummaryView] = useState(true);
  const [isShowApproval, setIsShowApproval] = useState(false);
  const [expandAll, setExpandAll] = useState(true);
  const [MNVMoverJobData, setMNVMoverJobData] = useState<any>([]);
  const [MNVMoverShiftData, setMNVMoverShiftData] = useState<any>([]);
  const [PendingApprovalsData, setPendingApprovalsData] = useState<any>([]);

  const [filterData, setFilterData] = useState({
    MNVMoverID: 0,
    ObjectStatus: -1,
    StartDate: getStartOfMonth(),
    EndDate: getEndOfMonth(),
  });

  const [addEditObject, setAddEditObject] = useState<any>({
    DataDate: new Date(),
    MNVMoverID: 0,
    Stairs: 0,
    Notes: "",
    ShiftData: [],
    ObjectStatus:0
  });

  const [reviewSourceTypeList, setReviewSourceTypeList] = useState([]);
  const [moversList, setMoversList] = useState([]);

  const [rateTypeList, setRateTypeList] = useState([
    // { PID: 1, Name: "Day Off" },
    { PID: 2, Name: "Porter" },
    { PID: 3, Name: "Driver" },
    { PID: 4, Name: "Sunday Porter" },
    { PID: 5, Name: "Sunday Driver" },
    { PID: 6, Name: "Bank Holiday Porter" },
    { PID: 7, Name: "Bank Holiday Driver" },
    // { PID: 8, Name: "Annual Leave" },
  ]);

  const [breakDurationTypeList, setBreakDurationTypeList] = useState([
    { PID: 1, Name: "IN" },
    { PID: 2, Name: "OUT" },
    { PID: 3, Name: "NOT REQUIRED" },
  ]);

  const [statusList, setStatusList] = useState([
    { PID: 0, Name: "Pending" },
    { PID: 1, Name: "Approved" },
    { PID: 2, Name: "Rejected" },
  ]);

  useEffect(() => {
    setFilterData((prevState: any) => ({
      ...prevState,
      StartDate: getStartOfMonth(),
      EndDate: getEndOfMonth(),
    }));
    getJobReviewGetJobDetailsAndMoversList(1);
    MNVMoverShiftDataGetHistoricalData();
  }, []);

  function MNVMoverShiftDataGetHistoricalData() {
    setIsAnimation(true);
    let object = {} as any;
    object.mnvmoverid = filterData.MNVMoverID;
    object.objectstatus = filterData.ObjectStatus;
    object.startdate = filterData.StartDate;
    object.enddate = filterData.EndDate;
    axiosAuth
      .post(
        AppContext.APIURL +
          "api/MNVMoverShiftData/MNVMoverShiftDataGetHistoricalData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_oResponse = response.data.Data;
          setMNVMoverJobData(l_oResponse[0]);
          setMNVMoverShiftData(l_oResponse[1]);
        }
        setIsAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setIsAnimation(false);
      });
  }

  function MNVMoverShiftDataGetPendingApprovalsData(isManual: any = 0) {
    //if (PendingApprovalsData.length == 0 || isManual == 1) {
    setIsAnimation(true);
    let object = {} as any;
    object.mnvmoverid = filterData.MNVMoverID;
    object.objectstatus = filterData.ObjectStatus;
    object.startdate = filterData.StartDate;
    object.enddate = filterData.EndDate;
    axiosAuth
      .post(
        AppContext.APIURL +
          "api/MNVMoverShiftData/MNVMoverShiftDataGetPendingApprovalsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_oResponse = response.data.Data;
          setPendingApprovalsData(l_oResponse);
        }
        setIsAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setIsAnimation(false);
      });
    //}
  }

  function getJobReviewGetJobDetailsAndMoversList(typeid: any) {
    setIsAnimation(true);
    let object = {} as any;
    object.typeid = typeid;
    object.jobid = addEditObject.jobid;
    axiosAuth
      .post(
        AppContext.APIURL +
          "api/MNVJobReview/MNVJobReviewGetJobDetailsAndMoversList",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_oResponse = response.data.Data;
          if (typeid == 1) {
            let filtered = l_oResponse[1].filter(
              (item: any) => item.IsContractor == false
            );
            setReviewSourceTypeList(l_oResponse[0]);
            setMoversList(filtered);
          }
        }
        setIsAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setIsAnimation(false);
      });
  }

  function addShiftData(TypeID: number) {
    let shiftDataLocal = addEditObject.ShiftData;
    if (shiftDataLocal == undefined || TypeID == 1) shiftDataLocal = [];
    shiftDataLocal.push({
      MNVMoverShiftDataID: 0,
      MNVMoverID: 0,
      DataDate: new Date(),
      MNVEmployeeRateTypeID: 0,
      StartTime: new Date(),
      EndTime: new Date(),
      BreakDurationType: 0,
      BreakDurationInMinutes: 0,
      TravelTime: 0,
      TotalHours: 0,
      IsDeleted: false,
    });
    if (TypeID == 1) {
      setAddEditObject((prevState: any) => ({
        ...prevState,
        DataDate: new Date(),
        MNVMoverID: 0,
        Stairs: 0,
        Notes: "",
        ShiftData: shiftDataLocal,
        ObjectStatus:0
      }));
    } else {
      setAddEditObject((prevState: any) => ({
        ...prevState,
        ShiftData: shiftDataLocal,
      }));
    }
  }

  function checkValidation() {
    let isValid = true;
    if (addEditObject.MNVMoverID == 0) {
      isValid = false;
      globalState.setNotificationObject({
        ShowPopup: true,
        Heading: "Alert",
        Message: "Mover Required",
      });
    } else if (addEditObject.DataDate == "") {
      isValid = false;
      globalState.setNotificationObject({
        ShowPopup: true,
        Heading: "Alert",
        Message: "Date Required",
      });
    }

    if (isValid == true) {
      MNVMoverShiftDataSaveCustom();
    }
  }

  function MNVMoverShiftDataSaveCustom() {
    setIsAnimation(true);
    let object = {} as any;

    object.mnvjobreviewsourcetypeid = addEditObject.MNVMoverJobDataID;
    object.datadate = addEditObject.DataDate;
    object.mnvmoverid = addEditObject.MNVMoverID;
    object.stairs = addEditObject.Stairs;
    object.notes = addEditObject.Notes;
    object.shiftData = addEditObject.ShiftData;

    axiosAuth
      .post(
        AppContext.APIURL + "api/MNVMoverShiftData/MNVMoverShiftDataSaveCustom",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data;
          MNVMoverShiftDataGetHistoricalData();
          setIsAddNew(0);
        }
        setIsAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setIsAnimation(false);
      });
  }

  function MNVMoverJobDataDeleteRecordCustom(
    MNVMoverJobDataID: number,
    MNVMoverShiftDataID: number
  ) {
    setIsAnimation(true);
    let object = {} as any;
    object.mnvmoverjobdataid = MNVMoverJobDataID;
    object.mnvmovershiftdataid = MNVMoverShiftDataID;
    axiosAuth
      .post(
        AppContext.APIURL +
          "api/MNVMoverJobData/MNVMoverJobDataDeleteRecordCustom",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          MNVMoverShiftDataGetHistoricalData();
        }
        setIsAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setIsAnimation(false);
      });
  }

  function MNVMoverShiftDataDeleteRecordCustom(
    MNVMoverJobDataID: number,
    MNVMoverShiftDataID: number
  ) {
    setIsAnimation(true);
    let object = {} as any;
    object.mnvmoverjobdataid = MNVMoverJobDataID;
    object.mnvmovershiftdataid = MNVMoverShiftDataID;
    axiosAuth
      .post(
        AppContext.APIURL +
          "api/MNVMoverShiftData/MNVMoverShiftDataDeleteRecordCustom",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          MNVMoverShiftDataGetHistoricalData();
        }
        setIsAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setIsAnimation(false);
      });
  }

  function MNVMoverShiftDataApproveData(
    TypeID: any,
    MNVMoverJobDataID: any,
    ObjectStatus: any
  ) {
    setIsAnimation(true);
    let object = {} as any;
    object.calltypeid = TypeID;
    object.mnvmoverjobdataid = MNVMoverJobDataID;
    object.objectstatus = ObjectStatus;

    axiosAuth
      .post(
        AppContext.APIURL +
          "api/MNVMoverShiftData/MNVMoverShiftDataApproveData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          MNVMoverShiftDataGetHistoricalData();
          MNVMoverShiftDataGetPendingApprovalsData(1);
        } else setIsAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setIsAnimation(false);
      });
  }

  function ConfirmationModalCallBack(obj: any) {
    if (obj.ID === 1) {
      if (obj.isConfirm === true) {
        MNVMoverJobDataDeleteRecordCustom(obj.SelectedID, obj.OtherID);
      }
    } else if (obj.ID === 2) {
      if (obj.isConfirm === true) {
        MNVMoverShiftDataDeleteRecordCustom(obj.SelectedID, obj.OtherID);
      }
    } else if (obj.ID === 3) {
      if (obj.isConfirm === true) {
        MNVMoverShiftDataApproveData(obj.SelectedID, obj.OtherID, 1);
      }
    } else if (obj.ID === 4) {
      if (obj.isConfirm === true) {
        MNVMoverShiftDataApproveData(obj.SelectedID, obj.OtherID, 2);
      }
    }
    globalState.setConfirmationModalObject({
      ShowPopup: false,
      Heading: "",
      Message: "",
      ID: 0,
      SelectedID: 0,
      OtherID: 0,
    });
  }

  function showHideChild(index: any) {
    let data = [...PendingApprovalsData];
    data[0][index].ShowChild = !data[0][index].ShowChild;
    setPendingApprovalsData(data);
  }

  function expandOrCollapseAll(isExpand: boolean) {
    setExpandAll(!isExpand);
    let data = [...PendingApprovalsData];
    if (isExpand) {
      for (let index = 0; index < data[0].length; index++) {
        data[0][index].ShowChild = true;
      }
    } else {
      for (let index = 0; index < data[0].length; index++) {
        data[0][index].ShowChild = false;
      }
    }

    setPendingApprovalsData(data);
  }

  return (
    <div className="inline-block-d width-100 p-all10">
      {isAnimation && <LoadingAnimation />}

      <ConfirmationModal
        ConfirmationObject={globalState.ConfirmationModalObject}
        ConfirmationModalCallBack={ConfirmationModalCallBack}
      />

      {isAddNew == 0 && !isShowApproval && (
        <>
          <>
            <div className="flex-d justify-btw mb-10">
              <div className="flex-da gap-10">
                <div className="field-input">
                  <select
                    className="input-field"
                    value={filterData.MNVMoverID}
                    onChange={(e) =>
                      setFilterData((prevState: any) => ({
                        ...prevState,
                        MNVMoverID: e.target.value,
                      }))
                    }
                  >
                    <option value={0}>Select Mover</option>
                    {moversList &&
                      moversList.map((item: any, index: any) => (
                        <React.Fragment key={index}>
                          <option value={item.MNVMoverID}>{item.Name}</option>
                        </React.Fragment>
                      ))}
                  </select>
                </div>
                <div className="field-input">
                  <select
                    className="input-field"
                    value={filterData.ObjectStatus}
                    onChange={(e) =>
                      setFilterData((prevState: any) => ({
                        ...prevState,
                        ObjectStatus: e.target.value,
                      }))
                    }
                  >
                    <option value={-1}>Select Status</option>
                    {statusList &&
                      statusList.map((item: any, index: any) => (
                        <React.Fragment key={index}>
                          <option value={item.PID}>{item.Name}</option>
                        </React.Fragment>
                      ))}
                  </select>
                </div>
                <div className="field-input">
                  <input
                    type="date"
                    placeholder="Lag"
                    value={filterData.StartDate}
                    onChange={(e) =>
                      setFilterData((prevState: any) => ({
                        ...prevState,
                        StartDate: e.target.value,
                      }))
                    }
                    className="input-field"
                  />
                </div>
                <div className="field-input">
                  <input
                    type="date"
                    placeholder="Lag"
                    value={filterData.EndDate}
                    onChange={(e) =>
                      setFilterData((prevState: any) => ({
                        ...prevState,
                        EndDate: e.target.value,
                      }))
                    }
                    className="input-field"
                  />
                </div>
                <div className="flex-daj btn-start">
                  <a
                    onClick={() => MNVMoverShiftDataGetHistoricalData()}
                    className="btn-primary flex-daj"
                  >
                    Filter
                  </a>
                </div>
              </div>

              <div className="flex-daj flex-gap10">
                <div className="flex-da flex-gap5">
                  <input
                    type="checkbox"
                    className="input-check lg-check"
                    checked={isShowApproval}
                    onClick={() => {
                      setIsShowApproval(!isShowApproval);
                      MNVMoverShiftDataGetPendingApprovalsData();
                    }}
                  />
                  <span className="txt-compare">Show Pending Approvals</span>
                </div>
                <div className="com-toggle">
                  <span className="txt-compare bold-font">Show Summary</span>

                  {isSummaryView == true && (
                    <a
                      className="com-btn com-sel2"
                      onClick={() => {
                        setIsSummaryView(false);
                      }}
                    >
                      <span></span>
                    </a>
                  )}
                  {isSummaryView == false && (
                    <a
                      className="com-btn com-sel"
                      onClick={() => {
                        setIsSummaryView(true);
                      }}
                    >
                      <span></span>
                    </a>
                  )}
                  <span className="txt-compare bold-font">Show Details</span>
                </div>
                <div className="flex-daj btn-start">
                  <a
                    onClick={() => {
                      addShiftData(1);
                      setIsAddNew(1);
                    }}
                    className="btn-primary flex-daj"
                  >
                    Add New
                  </a>
                </div>
              </div>
            </div>

            {isSummaryView == true && (
              <table className="reuse-table">
                <thead>
                  <tr>
                    <th>Mover</th>
                    <th>Date</th>
                    <th>Job Type</th>
                    <th>Total Hours</th>
                    <th>Rate Per Hour</th>
                    <th>Stairs</th>
                    <th>Rate Per Stair</th>
                    <th>Total Excl. Stairs</th>
                    <th>Daily Total</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {MNVMoverJobData &&
                    MNVMoverJobData.map((item: any, index: any) => (
                      <React.Fragment key={index}>
                        <tr key={index}>
                          <td>{item.MNVMoverName}</td>
                          <td>{moment(item.DataDate)?.format("DD/MM/YYYY")}</td>
                          <td>{item.MNVEmployeeRateTypeName}</td>
                          <td className="text-right">
                            {item.TotalHours.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td className="text-right">
                            £
                            {item.RatePerHour.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td className="text-right">{item.TotalStairs}</td>
                          <td className="text-right">
                            £
                            {item.RatePerStair.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td className="text-right">
                            £
                            {item.TotalExclStairs.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td className="text-right">
                            £
                            {item.TotalAmount.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td>{item.ObjectStatusName}</td>
                          <td className="text-center">
                            <div className="flex-daj flex-gap10">
                              <a
                                title={item.ObjectStatus == 0 ? "Edit" : "View"}
                                onClick={() => {
                                  let addEditObjectLocal = item;
                                  addEditObjectLocal.Stairs =
                                    addEditObjectLocal.TotalStairs;
                                  let filtered = MNVMoverShiftData.filter(
                                    (itemFilter: any) =>
                                      itemFilter.MNVMoverJobDataID ==
                                      item.MNVMoverJobDataID
                                  );
                                  addEditObjectLocal.ShiftData = [...filtered];
                                  setAddEditObject(addEditObjectLocal);
                                  setIsAddNew(2);
                                }}
                              >
                                <img
                                  src="images/edit.svg"
                                  width="18px"
                                  height="18px"
                                  alt="Edit"
                                />
                              </a>
                              {item.ObjectStatus == 0 && (
                                <>
                                  <a
                                    title="Delete"
                                    onClick={() =>
                                      globalState.setConfirmationModalObject({
                                        ShowPopup: true,
                                        Heading: "Alert",
                                        Message:
                                          "Are your sure, you want to delete this record?",
                                        ID: 1,
                                        SelectedID: item.MNVMoverJobDataID,
                                        OtherID: 0,
                                      })
                                    }
                                  >
                                    <img
                                      src="images/delete.png"
                                      width="18px"
                                      height="18px"
                                      alt="Delete"
                                    />
                                  </a>
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            )}

            {isSummaryView == false && (
              <table className="reuse-table">
                <thead>
                  <tr>
                    <th>Mover</th>
                    <th>Date</th>
                    <th>Job Type</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th>Break Duration Type</th>
                    <th>Break Duration In Minutes</th>
                    <th>Travel Time</th>
                    <th>Total Hours</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {MNVMoverShiftData &&
                    MNVMoverShiftData.map((item: any, index: any) => (
                      <React.Fragment key={index}>
                        <tr key={index}>
                          <td>{item.MNVMoverName}</td>
                          <td>{moment(item.DataDate)?.format("DD/MM/YYYY")}</td>
                          <td>{item.MNVEmployeeRateTypeName}</td>
                          <td>
                            {moment(item.StartTime).format("DD/MM/YYYY HH:mm")}
                          </td>
                          <td>
                            {moment(item.EndTime).format("DD/MM/YYYY HH:mm")}
                          </td>
                          <td>{item.BreakDurationTypeName}</td>
                          <td className="text-right">
                            {item.BreakDurationInMinutes}
                          </td>
                          <td className="text-right">{item.TravelTime}</td>
                          <td className="text-right">
                            {item.TotalHours.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td>{item.ObjectStatusName}</td>
                          <td className="text-center">
                            {item.ObjectStatus == 0 && (
                              <a
                                title="Delete"
                                onClick={() =>
                                  globalState.setConfirmationModalObject({
                                    ShowPopup: true,
                                    Heading: "Alert",
                                    Message:
                                      "Are your sure, you want to delete this record?",
                                    ID: 2,
                                    SelectedID: item.MNVMoverJobDataID,
                                    OtherID: item.MNVMoverShiftDataID,
                                  })
                                }
                              >
                                <img
                                  src="images/delete.png"
                                  width="18px"
                                  height="18px"
                                  alt="Delete"
                                />
                              </a>
                            )}
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            )}
          </>
        </>
      )}

      {(isAddNew == 1 || isAddNew == 2) && (
        <div className="inline-block-d text-center width-100 reuse-border p-all20">
          <>
            <div className="grid-d fr-111 gap-20">
              <div className="field-input grid-d">
                <span className="txt-compare flex-d">
                  <b>Mover</b>
                </span>
                <select
                  className="input-field w-100"
                  value={addEditObject.MNVMoverID}
                  onChange={(e) =>
                    setAddEditObject((prevState: any) => ({
                      ...prevState,
                      MNVMoverID: e.target.value,
                    }))
                  }
                >
                  <option value={0}>Select Mover</option>
                  {moversList &&
                    moversList.map((item: any, index: any) => (
                      <React.Fragment key={index}>
                        <option value={item.MNVMoverID}>{item.Name}</option>
                      </React.Fragment>
                    ))}
                </select>
              </div>

              <div className="field-input grid-d">
                <span className="txt-compare flex-d">
                  <b>Date</b>
                </span>
                <input
                  title="Data Date"
                  className="input-field w-100"
                  type="date"
                  value={moment(addEditObject.DataDate).format("YYYY-MM-DD")}
                  onChange={(e) =>
                    setAddEditObject((prevState: any) => ({
                      ...prevState,
                      DataDate: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            {addEditObject.ShiftData &&
              addEditObject.ShiftData.length > 0 &&
              addEditObject.ShiftData.map((item: any, index: any) => (
                <>
                  {item.IsDeleted == false && (
                    <React.Fragment key={index}>
                      <div className="accordion-top mt-10 flex-da justify-btw grey-accord">
                        {" "}
                        Shift {index + 1}
                        {(addEditObject.ObjectStatus == undefined ||
                          addEditObject.ObjectStatus == 0) && (
                          <a
                            onClick={() => {
                              let shiftDataLocal = addEditObject.ShiftData;
                              shiftDataLocal[index].IsDeleted = true;
                              setAddEditObject((prevState: any) => ({
                                ...prevState,
                                ShiftData: shiftDataLocal,
                              }));
                            }}
                          >
                            <img
                              src="images/delete.png"
                              width="18px"
                              height="18px"
                              alt="Delete"
                            />
                          </a>
                        )}
                      </div>
                      <div className="grid-d fr-111 gap-20">
                        <div className="field-input grid-d">
                          <span className="txt-compare flex-d">
                            <b>Job Type</b>
                          </span>
                          <select
                            className="input-field w-100"
                            value={item.MNVEmployeeRateTypeID}
                            onChange={(e) => {
                              let shiftDataLocal = addEditObject.ShiftData;
                              shiftDataLocal[index].MNVEmployeeRateTypeID =
                                e.target.value;
                              setAddEditObject((prevState: any) => ({
                                ...prevState,
                                ShiftData: shiftDataLocal,
                              }));
                            }}
                          >
                            <option value={0}>Select Job Type</option>
                            {rateTypeList &&
                              rateTypeList.map((item: any, index: any) => (
                                <React.Fragment key={index}>
                                  <option value={item.PID}>{item.Name}</option>
                                </React.Fragment>
                              ))}
                          </select>
                        </div>
                        <div className="field-input grid-d">
                          <span className="txt-compare flex-d">
                            <b>Start Time</b>
                          </span>
                          <input
                            title="Start Time"
                            className="input-field w-100"
                            type="datetime-local"
                            value={item.StartTime}
                            onChange={(e) => {
                              let shiftDataLocal = addEditObject.ShiftData;
                              shiftDataLocal[index].StartTime = e.target.value;
                              setAddEditObject((prevState: any) => ({
                                ...prevState,
                                ShiftData: shiftDataLocal,
                              }));
                            }}
                          />
                        </div>
                        <div className="field-input grid-d">
                          <span className="txt-compare flex-d">
                            <b>End Time</b>
                          </span>
                          <input
                            title="End Time"
                            className="input-field w-100"
                            type="datetime-local"
                            value={item.EndTime}
                            onChange={(e) => {
                              let shiftDataLocal = addEditObject.ShiftData;
                              shiftDataLocal[index].EndTime = e.target.value;
                              setAddEditObject((prevState: any) => ({
                                ...prevState,
                                ShiftData: shiftDataLocal,
                              }));
                            }}
                          />
                        </div>
                        <div className="field-input grid-d">
                          <span className="txt-compare flex-d">
                            <b>Break Duration Type</b>
                          </span>
                          <select
                            className="input-field w-100"
                            value={item.BreakDurationType}
                            onChange={(e) => {
                              let shiftDataLocal = addEditObject.ShiftData;
                              shiftDataLocal[index].BreakDurationType =
                                e.target.value;
                              setAddEditObject((prevState: any) => ({
                                ...prevState,
                                ShiftData: shiftDataLocal,
                              }));
                            }}
                          >
                            <option value={0}>
                              Select Break Duration Type
                            </option>
                            {breakDurationTypeList &&
                              breakDurationTypeList.map(
                                (item: any, index: any) => (
                                  <React.Fragment key={index}>
                                    <option value={item.PID}>
                                      {item.Name}
                                    </option>
                                  </React.Fragment>
                                )
                              )}
                          </select>
                        </div>
                        <div className="field-input grid-d">
                          <span className="txt-compare flex-d">
                            <b>Break Duration in Minutes</b>
                          </span>
                          <input
                            className="input-field w-100"
                            title="Break Duration In Minutes"
                            placeholder="Break Duration In Minutes"
                            value={item.BreakDurationInMinutes}
                            onChange={(e) => {
                              let shiftDataLocal = addEditObject.ShiftData;
                              shiftDataLocal[index].BreakDurationInMinutes =
                                e.target.value;
                              setAddEditObject((prevState: any) => ({
                                ...prevState,
                                ShiftData: shiftDataLocal,
                              }));
                            }}
                          />
                        </div>
                        <div className="field-input grid-d">
                          <span className="txt-compare flex-d">
                            <b>Travel Time</b>
                          </span>
                          <input
                            className="input-field w-100"
                            title="Travel Time"
                            placeholder="Travel Time"
                            value={item.TravelTime}
                            onChange={(e) => {
                              let shiftDataLocal = addEditObject.ShiftData;
                              shiftDataLocal[index].TravelTime = e.target.value;
                              setAddEditObject((prevState: any) => ({
                                ...prevState,
                                ShiftData: shiftDataLocal,
                              }));
                            }}
                          />
                        </div>
                        <div className="field-input grid-d">
                          <span className="txt-compare flex-d">
                            <b>Total Hours</b>
                          </span>
                          <input
                            className="input-field w-100"
                            title="Total Hours"
                            placeholder="Total Hours"
                            value={item.TotalHours}
                            onChange={(e) => {
                              let shiftDataLocal = addEditObject.ShiftData;
                              shiftDataLocal[index].TotalHours = e.target.value;
                              setAddEditObject((prevState: any) => ({
                                ...prevState,
                                ShiftData: shiftDataLocal,
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </>
              ))}

            {addEditObject.ShiftData.length < 5 && (
              <div className="flex-da btn-start mt-10 mb-10">
                {(addEditObject.ObjectStatus == undefined ||
                  addEditObject.ObjectStatus == 0) && (
                  <a
                    className="btn-primary flex-daj"
                    onClick={() => {
                      addShiftData(2);
                    }}
                  >
                    Add Shift
                  </a>
                )}
              </div>
            )}
            <div className="grid-d fr-111 gap-20">
              <div className="field-input grid-d">
                <span className="txt-compare flex-d">
                  <b>Stairs</b>
                </span>
                <input
                  title="Stairs"
                  placeholder="Stairs"
                  className="input-field w-100"
                  type="number"
                  value={addEditObject.Stairs}
                  onChange={(e) =>
                    setAddEditObject((prevState: any) => ({
                      ...prevState,
                      Stairs: e.target.value,
                    }))
                  }
                />
              </div>

              <div className="field-input grid-d">
                <span className="txt-compare flex-d">
                  <b>Notes</b>
                </span>
                <textarea
                  title="Notes"
                  placeholder="Notes"
                  className="input-field w-100"
                  value={addEditObject.Notes}
                  onChange={(e) =>
                    setAddEditObject((prevState: any) => ({
                      ...prevState,
                      Notes: e.target.value,
                    }))
                  }
                />
              </div>
            </div>

            <div className="flex-daj gap-10 mt-20">
              <div className="flex-daj btn-start">
                {(addEditObject.ObjectStatus == undefined ||
                  addEditObject.ObjectStatus == 0) && (
                  <a
                    className="btn-primary flex-daj"
                    onClick={() => checkValidation()}
                  >
                    {isAddNew == 1 ? "Save" : "Update"}
                  </a>
                )}
              </div>
              <div className="flex-daj btn-start">
                <a
                  className="btn-primary flex-daj"
                  onClick={() => setIsAddNew(0)}
                >
                  Cancel
                </a>
              </div>
            </div>
          </>
        </div>
      )}

      {/* Time Logs Approval */}
      {isShowApproval && (
        <>
          <div className="flex-da justify-btw mb-10">
            <span className="txt-lg">Time Logs Approval</span>
            <div className="flex-daj flex-gap10">
              <div className="flex-da flex-gap5">
                <input
                  type="checkbox"
                  className="input-check lg-check"
                  checked={isShowApproval}
                  onClick={() => setIsShowApproval(!isShowApproval)}
                />
                <span className="txt-compare">Show Pending Approvals</span>
              </div>
              <div className="flex-daj btn-start">
                {expandAll == true && (
                  <a
                    className="btn-primary flex-daj"
                    onClick={() => expandOrCollapseAll(true)}
                  >
                    Expand All
                  </a>
                )}
                {expandAll == false && (
                  <a
                    className="btn-primary flex-daj"
                    onClick={() => expandOrCollapseAll(false)}
                  >
                    Collapse All
                  </a>
                )}
              </div>
              <div className="flex-daj btn-start">
                <a
                  className="btn-primary flex-daj"
                  onClick={() => {
                    globalState.setConfirmationModalObject({
                      ShowPopup: true,
                      Heading: "Alert",
                      Message: "Are your sure, you want to approve all?",
                      ID: 3,
                      SelectedID: 2,
                      OtherID: 0,
                    });
                  }}
                >
                  Approve All
                </a>
              </div>
              <div className="flex-daj btn-start">
                <a
                  className="btn-primary flex-daj"
                  onClick={() => {
                    globalState.setConfirmationModalObject({
                      ShowPopup: true,
                      Heading: "Alert",
                      Message: "Are your sure, you want to reject all?",
                      ID: 4,
                      SelectedID: 2,
                      OtherID: 0,
                    });
                  }}
                >
                  Reject All
                </a>
              </div>
            </div>
          </div>

          {PendingApprovalsData && PendingApprovalsData.length > 2 && (
            <>
              {PendingApprovalsData[0].map((item: any, index: any) => (
                <>
                  <React.Fragment key={"main" + index}>
                    <div className="accordion-top mt-10 flex-da justify-btw">
                      <a
                        onClick={() => {
                          showHideChild(index);
                        }}
                      >
                        {item.MNVMoverName} -{" "}
                        {moment(item.DataDate).format("DD/MM/YYYY")}
                      </a>
                      <div className="flex-da flex-gap15">
                        <a
                          title="Approve"
                          onClick={() => {
                            globalState.setConfirmationModalObject({
                              ShowPopup: true,
                              Heading: "Alert",
                              Message:
                                "Are your sure, you want to approve this?",
                              ID: 3,
                              SelectedID: 1,
                              OtherID: item.MNVMoverJobDataID,
                            });
                          }}
                        >
                          <img
                            src="/images/tick-b.svg"
                            width="18"
                            height="16"
                          />
                        </a>
                        <a
                          title="Reject"
                          onClick={() => {
                            globalState.setConfirmationModalObject({
                              ShowPopup: true,
                              Heading: "Alert",
                              Message:
                                "Are your sure, you want to reject this?",
                              ID: 4,
                              SelectedID: 1,
                              OtherID: item.MNVMoverJobDataID,
                            });
                          }}
                        >
                          <img
                            src="/images/cross-b.svg"
                            width="14"
                            height="14"
                          />
                        </a>
                      </div>
                    </div>
                    {(item.ShowChild == undefined ||
                      item.ShowChild == true) && (
                      <>
                        <table className="reuse-table">
                          <thead>
                            <tr>
                              <th>Job id</th>
                              <th>job type</th>
                              <th>start time</th>
                              <th>end time</th>
                              <th>hours</th>
                              <th>stairs</th>
                            </tr>
                          </thead>
                          <tbody>
                            {PendingApprovalsData[1].map(
                              (itemJob: any, indexJob: any) => (
                                <>
                                  {item.MNVMoverID == itemJob.MNVMoverID &&
                                    new Date(item.DataDate).setHours(
                                      0,
                                      0,
                                      0,
                                      0
                                    ) ==
                                      new Date(itemJob.RequestedDate).setHours(
                                        0,
                                        0,
                                        0,
                                        0
                                      ) && (
                                      <React.Fragment key={"job" + indexJob}>
                                        <tr>
                                          <td>{itemJob.JobID}</td>
                                          <td>{itemJob.RoleName}</td>
                                          <td>
                                            {moment(itemJob.StartTime).format(
                                              "DD/MM/YYYY HH:mm"
                                            )}
                                          </td>
                                          <td>
                                            {moment(itemJob.EndTime).format(
                                              "DD/MM/YYYY HH:mm"
                                            )}
                                          </td>
                                          <td className="text-right">
                                            {itemJob.RequiredVanTime}
                                          </td>
                                          <td className="text-right">
                                            {itemJob.Stairs}
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                    )}
                                </>
                              )
                            )}
                            <tr>
                              <td>
                                <b>Total</b>
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td className="text-right">
                                <b>{item.JobTotalHours}</b>
                              </td>
                              <td className="text-right">
                                <b>{item.JobTotalStairs}</b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table className="reuse-table mt-10">
                          <thead>
                            <tr>
                              <th>Shift</th>
                              <th>job type</th>
                              <th>start time</th>
                              <th>end time</th>
                              <th>break type</th>
                              <th>break duration</th>
                              <th>travel time</th>
                              <th>hours</th>
                            </tr>
                          </thead>
                          <tbody>
                            {PendingApprovalsData[2].map(
                              (itemShift: any, indexShift: any) => (
                                <>
                                  {item.MNVMoverID == itemShift.MNVMoverID &&
                                    new Date(item.DataDate).setHours(
                                      0,
                                      0,
                                      0,
                                      0
                                    ) ==
                                      new Date(itemShift.DataDate).setHours(
                                        0,
                                        0,
                                        0,
                                        0
                                      ) && (
                                      <React.Fragment
                                        key={"shift" + indexShift}
                                      >
                                        <tr>
                                          <td>{itemShift.ShiftNumber}</td>
                                          <td>
                                            {itemShift.MNVEmployeeRateTypeName}
                                          </td>
                                          <td>
                                            {moment(itemShift.StartTime).format(
                                              "DD/MM/YYYY HH:mm"
                                            )}
                                          </td>
                                          <td>
                                            {moment(itemShift.EndTime).format(
                                              "DD/MM/YYYY HH:mm"
                                            )}
                                          </td>
                                          <td>
                                            {itemShift.BreakDurationTypeName}
                                          </td>
                                          <td className="text-right">
                                            {itemShift.BreakDurationInMinutes}
                                          </td>
                                          <td className="text-right">
                                            {itemShift.TravelTime}
                                          </td>
                                          <td className="text-right">
                                            {itemShift.TotalHours}
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                    )}
                                </>
                              )
                            )}
                            <tr>
                              <td>
                                <b>Total Hours</b>
                              </td>
                              <td colSpan={7} className="text-right">
                                <b>{item.TotalHours}</b>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Stairs</b>
                              </td>
                              <td colSpan={7} className="text-right">
                                <b>{item.TotalStairs}</b>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Comments</b>
                              </td>
                              <td colSpan={7} className="p-all0">
                                <textarea
                                  disabled
                                  placeholder="Comments"
                                  value={item.Notes}
                                ></textarea>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </>
                    )}
                  </React.Fragment>
                </>
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default MNVMoverShiftData;
