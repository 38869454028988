import { Link, NavLink } from "react-router-dom";
import React from "react";
import { useState, useEffect, useRef } from "react";
import { useGlobalState } from "../Context/GlobalStateContext";
import Notification from "./Notification";
import axiosAuth from "../Axios/Interceptors";
import AppContext from "../Context/Context";
export default function Header() {
  const globalState = useGlobalState();
  const [dataArray, setDataArray] = useState([]);

  useEffect(() => {}, []);

  const LogOutUser = () => {
    AppContext.SetCookie("Token", "", 0);
    AppContext.SetCookie("RefreshToken", "", 0);
    globalState.setLogInUserName("");
    globalState.setLogInUserImage("");
    globalState.setLogInUserRole("");
    AppContext.LogInUserName = "";
    window.location.href = "/login";
  };

  function NotificationCallBack() {
    globalState.setNotificationObject({
      ShowPopup: false,
      Heading: "",
      Message: "",
    });
  }

  return (
    <>
      <Notification
        NotificationObject={globalState.NotificationObject}
        NotificationCallBack={NotificationCallBack}
      />
      {globalState.LogInUserName !== "" && (
        <header className="flex-daj header">
          <div className="flex-da justify-end reuse-width header-content">
            <nav className="nav-menu flex-da">
              {/* {globalState.LogInUserName === "" && ( */}
              <div className="nav-item p-relative">
                <div className="sep-click">
                  <Link className="flex-da click-link" to={""}>
                    {globalState.LogInUserName}
                    <img
                      src="/images/arrow-down.svg"
                      width="9"
                      height="17"
                      alt="arrow down"
                      className="hide-980"
                    />
                  </Link>
                  {/* <a className="flex-da click-open">
                    <img
                      src="/images/arrow-down.svg"
                      width="9"
                      height="17"
                      alt="arrow down"
                      className="show-980"
                    />
                  </a> */}
                </div>
                <div className="sub-menu">
                  <Link to={""}>
                    <span className="show-980"></span>
                    <p>
                      {" "}
                      <a onClick={LogOutUser}>LogOut</a>
                    </p>
                  </Link>
                </div>
              </div>
            </nav>
          </div>
        </header>
      )}
      {globalState.LogInUserName !== "" && (
        <div className="left-menu">
          <div className="left-menu-container">
            <div className="left-menu-items">
              <Link className="flex-da click-link" to={"/employeeview"}>
                Employee View
              </Link>
            </div>
            <div className="left-menu-items">
              <Link className="flex-da click-link" to={"/employeesummary"}>
                Employee Summary
              </Link>
            </div>
            <div className="left-menu-items">
              <Link
                className="flex-da click-link"
                to={"/employeebonusanddeductions"}
              >
                Employee Bonus And Deductions
              </Link>
            </div>
            <div className="left-menu-items">
              <Link className="flex-da click-link" to={"/employeetimelog"}>
                Employee Time Logs
              </Link>
            </div>
            <div className="left-menu-items">
              <Link className="flex-da click-link" to={"/employeerates"}>
                Employee Rates
              </Link>
            </div>
            <div className="left-menu-items">
              <Link className="flex-da click-link" to={"/jobreview"}>
                Job Review
              </Link>
            </div>
            <div className="left-menu-items">
              <Link
                className="flex-da click-link"
                to={"/jobreviewmonthlystats"}
              >
                Job Review Monthly Stats
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
