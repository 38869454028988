import axios from "axios";
import AppContext from "../Context/Context";

const axiosAuth = axios.create();

//we intercept every requests
axiosAuth.interceptors.request.use(
  async function (config: any) {
    //anything you want to attach to the requests such as token
    var token = AppContext.GetCookie("Token");
    var sntkn = AppContext.GetCookie("sntkn");
    if (token && token !== "" && !AppContext.IsJwtExpired(token)) {
      config.headers.Authorization = "Bearer " + token;
      config.headers.sntkn = sntkn;
    } else {
      await AppContext.GetNewJWTTokenFromRefreshToken(false);
      token = AppContext.GetCookie("Token");
      config.headers.Authorization = "Bearer " + token;
      config.headers.sntkn = sntkn;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//we intercept every response
axiosAuth.interceptors.response.use(
  async function (response) {
   // console.log(response)
    let sntkn = response.data.sntkn;
    if (sntkn) {
      AppContext.SetCookie("sntkn", sntkn, 360);
    }
    return response;
  },
  (error) => {
    //check for authentication or anything like that
    return Promise.reject(error);
  }
);

export default axiosAuth;
