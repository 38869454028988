import React from "react";
import { useState, useEffect, useRef } from "react";
type Props = {
  ConfirmationObject: {
    ShowPopup: boolean;
    Heading: string;
    Message: string;
    ID: number;
    SelectedID: number;
    OtherID: number;
  };
  ConfirmationModalCallBack: any;
};
function ConfirmationModal(props: Props) {
  const [ShowPopup, setShowPopup] = useState(
    props.ConfirmationObject.ShowPopup
  );
  function ClosePopUp(value: boolean) {
    props.ConfirmationModalCallBack({
      show: false,
      isConfirm: value,
      ID: props.ConfirmationObject.ID,
      SelectedID: props.ConfirmationObject.SelectedID,
      OtherID: props.ConfirmationObject.OtherID,
    });
  }
  return (
    <div>
      {props.ConfirmationObject.ShowPopup && (
        <div className="popup-help popup-help-index new-z-index">
          <div className="help-popup-display">
            <div className="help-inner sm-popup">
              <div className="help-head flex-da justify-btw">
                <h3 className="bold-font flex-d m-all0">
                  {props.ConfirmationObject.Heading}
                </h3>
                <a className="close-help" onClick={() => ClosePopUp(false)}>
                  <img
                    src="/images/close.svg"
                    width="15px"
                    height="15px"
                    alt="cross"
                  />
                </a>
              </div>
              <div className="popup-message mt--15 p-all0">
                <span
                  dangerouslySetInnerHTML={{
                    __html: props.ConfirmationObject.Message,
                  }}
                ></span>
              </div>
              <div className="flex-daj gap-10 mt-20">
                <div className="flex-daj btn-start">
                  <a
                    className="btn-primary flex-daj"
                    onClick={() => ClosePopUp(false)}
                  >
                    No
                  </a>
                </div>
                <div className="flex-daj btn-start">
                  <a
                    className="btn-primary flex-daj"
                    onClick={() => ClosePopUp(true)}
                  >
                    Yes
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ConfirmationModal;
