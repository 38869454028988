import React, { useState, useEffect } from "react";
import AppContext from "../Context/Context";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import axiosAuth from "../Axios/Interceptors";
import moment from "moment";
function JobReviewMonthlyStats() {
  const [isAnimation, setIsAnimation] = useState(false);

  const [jobReviewMonthlyStatsData, setJobReviewMonthlyStatsData] =
    useState<any>([]);

  const [jobReviewObject, setJobReviewObject] = useState({
    NumericYear: new Date().getFullYear(),
  });

  useEffect(() => {
    MNVJobReviewGetMonthlyStatsData();
  }, [jobReviewObject]);

  function MNVJobReviewGetMonthlyStatsData() {
    setIsAnimation(true);
    let object = {} as any;
    object.numericyear = jobReviewObject.NumericYear;
    axiosAuth
      .post(
        AppContext.APIURL + "api/MNVJobReview/MNVJobReviewGetMonthlyStatsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_oResponse = response.data.Data;
          setJobReviewMonthlyStatsData(l_oResponse[0]);
        }
        setIsAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setIsAnimation(false);
      });
  }

  return (
    <>
      {isAnimation && <LoadingAnimation />}

      <div className="inline-block-d width-100 p-all10">
        <>
          <div className="field-input mb-10">
            <select
              className="input-field"
              value={jobReviewObject.NumericYear}
              onChange={(e) =>
                setJobReviewObject((prevState: any) => ({
                  ...prevState,
                  NumericYear: e.target.value,
                }))
              }
            >
              <option value={0}>Select Year</option>
              {AppContext.yearsList &&
                AppContext.yearsList.map((item: any, index: any) => (
                  <React.Fragment key={index}>
                    <option value={item.ID}>{item.Name}</option>
                  </React.Fragment>
                ))}
            </select>
          </div>

          <table className="reuse-table">
            <thead>
              <tr>
                <th rowSpan={2}>Mover Name</th>
                <th colSpan={2}>January</th>
                <th colSpan={2}>February</th>
                <th colSpan={2}>March</th>
                <th colSpan={2}>April</th>
                <th colSpan={2}>May</th>
                <th colSpan={2}>June</th>
                <th colSpan={2}>July</th>
                <th colSpan={2}>August</th>
                <th colSpan={2}>September</th>
                <th colSpan={2}>October</th>
                <th colSpan={2}>November</th>
                <th colSpan={2}>December</th>
              </tr>
              <tr>
                <th>Reviews</th>
                <th>Amount</th>
                <th>Reviews</th>
                <th>Amount</th>
                <th>Reviews</th>
                <th>Amount</th>
                <th>Reviews</th>
                <th>Amount</th>
                <th>Reviews</th>
                <th>Amount</th>
                <th>Reviews</th>
                <th>Amount</th>
                <th>Reviews</th>
                <th>Amount</th>
                <th>Reviews</th>
                <th>Amount</th>
                <th>Reviews</th>
                <th>Amount</th>
                <th>Reviews</th>
                <th>Amount</th>
                <th>Reviews</th>
                <th>Amount</th>
                <th>Reviews</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {jobReviewMonthlyStatsData &&
                jobReviewMonthlyStatsData.map((Item: any, index: any) => (
                  <React.Fragment key={index}>
                    <tr key={index}>
                      <td>{Item.Name}</td>
                      <td className="text-right">{Item.JanCount}</td>
                      <td className="text-right">
                        £
                        {Item.JanAmount.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                      <td className="text-right">{Item.FebCount}</td>
                      <td className="text-right">
                        £
                        {Item.FebAmount.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                      <td className="text-right">{Item.MarCount}</td>
                      <td className="text-right">
                        £
                        {Item.MarAmount.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                      <td className="text-right">{Item.AprCount}</td>
                      <td className="text-right">
                        £
                        {Item.AprAmount.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                      <td className="text-right">{Item.MayCount}</td>
                      <td className="text-right">
                        £
                        {Item.MayAmount.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                      <td className="text-right">{Item.JunCount}</td>
                      <td className="text-right">
                        £
                        {Item.JunAmount.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                      <td className="text-right">{Item.JulCount}</td>
                      <td className="text-right">
                        £
                        {Item.JulAmount.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                      <td className="text-right">{Item.AugCount}</td>
                      <td className="text-right">
                        £
                        {Item.AugAmount.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                      <td className="text-right">{Item.SepCount}</td>
                      <td className="text-right">
                        £
                        {Item.SepAmount.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                      <td className="text-right">{Item.OctCount}</td>
                      <td className="text-right">
                        £
                        {Item.OctAmount.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                      <td className="text-right">{Item.NovCount}</td>
                      <td className="text-right">
                        £
                        {Item.NovAmount.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                      <td className="text-right">{Item.DecCount}</td>
                      <td className="text-right">
                        £
                        {Item.DecAmount.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
            </tbody>
          </table>
        </>
      </div>
    </>
  );
}

export default JobReviewMonthlyStats;
