import React, { useState, useEffect, useRef } from "react";
import { addCommas, removeNonNumeric } from "../Shared/Utility";

type Props = {
  Prefix: string;
  Suffix: string;
  ToFixed: number;
  ColumnName: string;
  PID: any;
  Value: any;
  ClassName: string;
  Disabled: boolean;
  onChangeHandler: Function;
  onBlurHandler: Function;
};

function InputMask(props: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState<any>("");



  useEffect(() => {
    setValue(props.Value);
  }, [props.Value, props.Prefix, props.Suffix, props.ToFixed]);


  function handleInputChange(ColumnNames: any, PID: any, Value: any) {
    debugger;
    if (props.Suffix == "%") {
      let l_value = removeNonNumeric(Value);
      if (l_value > 100) {
        Value = 100;
      }
    }

let thisValue = addCommas(removeNonNumeric(Value));

    props.onChangeHandler({
      ColumnNames: ColumnNames,
      PID: PID,
      Value: thisValue,
    });

    // Preserve cursor position
    const input = inputRef.current;
    if (input) {
      const position = input.selectionStart;
      window.requestAnimationFrame(() => {
        input.setSelectionRange(position, position);
      });
    }
  }

  function handleInputBlur(ColumnNames: any, PID: any, Value: any) {
    props.onBlurHandler({ ColumnNames: ColumnNames, PID: PID, Value: Value });
  }

  return (
    <>
      <input
      ref={inputRef}
        disabled={props.Disabled === false ? true : false}
        className={props.ClassName}
        type="text"
        value={
          props.Prefix +
          props.Value?.toLocaleString(undefined, {
            minimumFractionDigits: props.ToFixed,
            maximumFractionDigits: props.ToFixed,
          }) +
          props.Suffix
        }
        onChange={(e) =>
          handleInputChange(props.ColumnName, props.PID, e.target.value)
        }
        onBlur={(e) =>
          handleInputBlur(props.ColumnName, props.PID, e.target.value)
        }
      />
    </>
  );
}

export default InputMask;
