import React from "react";
import { useState, useEffect, useRef } from "react";

type Props = {
  locked: boolean;
  setLocked: Function;
};
function LockUnlock(props: Props) {
  return (
    <>
      {props.locked == false ? (
        <a
          className="btn-primary flex-daj btn-grid"
          title="Click to un-lock"
          onClick={() => props.setLocked(true)}
        >
          <img
            src="images/lock-w.svg"
            width="16px"
            height="18px"
            alt="unlock"
          />
        </a>
      ) : (
        <a
          className="btn-primary flex-daj btn-grid"
          title="Click to lock"
          onClick={() => props.setLocked(false)}
        >
          <img src="images/un-lock.svg" width="16px" height="18px" alt="lock" />
        </a>
      )}
    </>
  );
}

export default LockUnlock;
