import React, { useContext, useEffect } from "react";
import { useGlobalState } from "../Context/GlobalStateContext";
type LogInUserNameDisplayProps = {
  onUserNameChange: (userName: string | undefined) => void;
};

const LogInUserName: React.FC<LogInUserNameDisplayProps> = ({ onUserNameChange }) => {
  const globalState = useGlobalState();

  useEffect(() => {
    if (globalState?.LogInUserName !== undefined) {
      // Call the parent's callback with the updated LogInUserName
      onUserNameChange(globalState.LogInUserName);
    }
  }, [globalState?.LogInUserName, onUserNameChange]); // Re-run effect whenever LogInUserName changes

  return null; // This component doesn't render anything itself
};

export default LogInUserName;
