
import React, { useState, useEffect } from "react";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
function ForgotPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [forgotPasswordObject, setForgotPasswordObject] = useState<any>({
    Email: "",
    Code: "",
    Password: "",
    ConfirmPassword: "",
    VerifyCode: false,
    ErrorMessage: "",
    LoadingState: false,
  });

  function submitEmailForgotPassword() {
    if (forgotPasswordObject.Email == "") {
      setForgotPasswordObject((prevState: any) => ({
        ...prevState,
        ErrorMessage: "Please enter email",
      }));
    } else {
      forgotPasswordObject.ErrorMessage = "";
      setForgotPasswordObject((prevState: any) => ({
        ...prevState,
        ErrorMessage: "",
      }));

      if (AppContext.IsValidEmail(forgotPasswordObject.Email)) {
        forgotPasswordObject.ErrorMessage = "";
        setForgotPasswordObject((prevState: any) => ({
          ...prevState,
          ErrorMessage: "",
        }));

        sendCodeForForgotPassword();
      } else {
        forgotPasswordObject.ErrorMessage = "Please enter valid email.";
        setForgotPasswordObject((prevState: any) => ({
          ...prevState,
          ErrorMessage: "Please enter valid email.",
        }));
      }
    }
  }

  function sendCodeForForgotPassword() {
    let object = {} as any;
    object.email = forgotPasswordObject.Email;
    object.crdsvvl = AppContext.GetCookie("crdsvvl");
    setForgotPasswordObject((prevState: any) => ({
      ...prevState,
      LoadingState: true,
    }));
    axiosAuth
      .post(
        AppContext.APIURL + "api/SiteUser/SendCodeForForgotPasswordViaEmail",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          setForgotPasswordObject((prevState: any) => ({
            ...prevState,
            ErrorMessage: response.data.Message,
            VerifyCode: true,
          }));
        } else {
          setForgotPasswordObject((prevState: any) => ({
            ...prevState,
            ErrorMessage: response.data.Message,
          }));
        }
        setForgotPasswordObject((prevState: any) => ({
          ...prevState,
          LoadingState: false,
        }));
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setForgotPasswordObject((prevState: any) => ({
          ...prevState,
          LoadingState: false,
        }));
      });
  }
  function checkResetPasswordValidation(Type: any) {
    var l_boolOkyToGo = true;
    var ThisErrorMessage = "";

    setForgotPasswordObject((prevState: any) => ({
      ...prevState,
      ErrorMessage: "",
    }));

    if (Type == 0 || Type == 1) {
      ThisErrorMessage = "";
      if (forgotPasswordObject.Password == "") {
        ThisErrorMessage = "Please Enter Password.";

        l_boolOkyToGo = false;
      } else {
        var DataPassword = AppContext.PTCheckPasswordValidation(
          forgotPasswordObject.Password
        );
        if (!DataPassword.Valid) {
          l_boolOkyToGo = false;

          setForgotPasswordObject((prevState: any) => ({
            ...prevState,
            ErrorMessage: DataPassword.Message,
          }));
        }
      }
    }
    if (Type == 0 || Type == 2) {
      if (forgotPasswordObject.ConfirmPassword == "") {
        ThisErrorMessage = "Please Enter Confirm password.";
        if (forgotPasswordObject.Password == "") {
          ThisErrorMessage = "Please Enter password and Confirm password.";
        }
        l_boolOkyToGo = false;
      }
    }
    if (
      l_boolOkyToGo &&
      forgotPasswordObject.Password !== "" &&
      forgotPasswordObject.ConfirmPassword !== ""
    ) {
      if (
        forgotPasswordObject.Password !== forgotPasswordObject.ConfirmPassword
      ) {
        ThisErrorMessage = "Password and confirm password do not match.";
        l_boolOkyToGo = false;
      }
    } else if (Type == 0 || Type == 3) {
      if (forgotPasswordObject.Code == "") {
        setForgotPasswordObject((prevState: any) => ({
          ...prevState,
          ErrorMessage: "Please enter valid code",
        }));
        l_boolOkyToGo = false;
      }
    }

    if (l_boolOkyToGo && Type == 0) {
      updatePassword();
    } else {
      if (ThisErrorMessage != "")
        setForgotPasswordObject((prevState: any) => ({
          ...prevState,
          ErrorMessage: ThisErrorMessage,
        }));
    }
  }

  function updatePassword() {
    let object = {} as any;
    object.email = forgotPasswordObject.Email;
    object.codetype = 2;
    object.code = forgotPasswordObject.Code;
    object.password = forgotPasswordObject.Password;
    object.confirmpassword = forgotPasswordObject.ConfirmPassword;
    object.crdsvvl = AppContext.GetCookie("crdsvvl");
    setForgotPasswordObject((prevState: any) => ({
      ...prevState,
      LoadingState: true,
    }));

    axiosAuth
      .post(
        AppContext.APIURL +
          "api/SiteUser/VerifCodeForForgotPasswordAndUpdatePassword",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          window.location.href = "/login";
        } else {
          setForgotPasswordObject((prevState: any) => ({
            ...prevState,
            ErrorMessage: response.data.Message,
          }));
        }
        setForgotPasswordObject((prevState: any) => ({
          ...prevState,
          LoadingState: false,
        }));
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setForgotPasswordObject((prevState: any) => ({
          ...prevState,
          LoadingState: false,
        }));
      });
  }

  return (
    <>

      {forgotPasswordObject.LoadingState && <LoadingAnimation />}
      <div className="login-fields inline-block-d text-center width-100">
        {!forgotPasswordObject.VerifyCode ? (
          <>
            <div className="input-center">
              <h1 className="login-heading">Reset Password</h1>
              <input
                type="text"
                className="input-field flex-d width-100"
                placeholder="Email address"
                value={forgotPasswordObject.Email}
                onChange={(e) =>
                  setForgotPasswordObject((prevState: any) => ({
                    ...prevState,
                    Email: e.target.value,
                  }))
                }
              />

              <div className="login-btn flex-dj width-100">
                <a
                  className="btn-primary flex-daj"
                  onClick={() => submitEmailForgotPassword()}
                >
                  Submit
                </a>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="input-center">
              <h1 className="login-heading">Forgot Password</h1>
              <div className="p-relative width-100">
                <input
                  type={showPassword ? "text" : "password"}
                  className="input-field flex-d width-100"
                  placeholder="New password"
                  value={forgotPasswordObject.Password}
                  onChange={(e) =>
                    setForgotPasswordObject((prevState: any) => ({
                      ...prevState,
                      Password: e.target.value,
                    }))
                  }
                  onKeyUp={() => checkResetPasswordValidation(1)}
                />
                <a
                  onClick={() => setShowPassword(!showPassword)}
                  className="show-hide-pass"
                >
                  {!showPassword && (
                    <img
                      src="https://dft8zj8i6wy63.cloudfront.net/mnv/images/eye.svg"
                      width="18"
                      height="14"
                      alt="Eye"
                    />
                  )}
                  {showPassword && (
                    <img
                      src="https://dft8zj8i6wy63.cloudfront.net/mnv/images/eye-slash.png"
                      width="18"
                      height="14"
                      alt="Eye-Slash"
                    />
                  )}
                </a>
              </div>
              <div className="flex-d width-100 input-gap"></div>
              <input
                type="password"
                className="input-field flex-d width-100"
                placeholder="Confirm new password"
                value={forgotPasswordObject.ConfirmPassword}
                onChange={(e) =>
                  setForgotPasswordObject((prevState: any) => ({
                    ...prevState,
                    ConfirmPassword: e.target.value,
                  }))
                }
                onKeyUp={() => checkResetPasswordValidation(2)}
              />
              <div className="flex-d width-100 input-gap"></div>
              <input
                type="text"
                className="input-field flex-d width-100"
                placeholder="6-digit code"
                value={forgotPasswordObject.Code}
                onChange={(e) =>
                  setForgotPasswordObject((prevState: any) => ({
                    ...prevState,
                    Code: e.target.value,
                  }))
                }
                onKeyUp={() => checkResetPasswordValidation(3)}
              />
              <div className="login-btn flex-dj width-100">
                <a
                  className="btn-primary flex-daj"
                  onClick={() => checkResetPasswordValidation(0)}
                >
                  Verify
                </a>
              </div>
            </div>
          </>
        )}

        <span
          className="error-message flex-dj width-100 input-center"
          dangerouslySetInnerHTML={{
            __html: forgotPasswordObject.ErrorMessage,
          }}
        ></span>
      </div>
    </>
  );
}

export default ForgotPassword;
