

import React, { useState, useEffect, useRef } from "react";
import AppContext from "../Context/Context";
import * as CountryCode from "./../Shared/CounterCodes";
type Props = {
  SelectedMobileNumber: string;
  SetSelectedMobileNumber: Function;
};

export default function MobileInput(props: Props) {
  const [SelectedCounrtyCode, setSelectedCounrtyCode] = useState("+44");
  const [SelectedMobile, setSelectedMobile] = useState("");

  const [showDropDown, setShowDropDown] = useState(false);

  const [countryCode, setCountryCode] = useState<any>([]);
  const [searchCountry, setSearchCountry] = useState("");

  useEffect(() => {
    let l_SelectedCounrtyCode = "+44";
    let l_SelectedMobile = "";
    if (
      props.SelectedMobileNumber.length > 0 &&
      props.SelectedMobileNumber.includes("-")
    ) {
      var MobileParam = props.SelectedMobileNumber.split("-");

      if (MobileParam && MobileParam.length === 2) {
        if (
          MobileParam &&
          MobileParam.length > 0 &&
          MobileParam[0].length > 0 &&
          MobileParam[0].length < 6
        ) {
          l_SelectedCounrtyCode = MobileParam[0];
        }
        if (
          MobileParam &&
          MobileParam.length > 1 &&
          MobileParam[1].length > 0
        ) {
          l_SelectedMobile = MobileParam[1];
        }
      } else if (MobileParam && MobileParam.length >= 2) {
        //to handle mutiple -
        if (
          MobileParam &&
          MobileParam.length > 0 &&
          MobileParam[0].length > 0 &&
          MobileParam[0].length < 6
        ) {
          l_SelectedCounrtyCode = MobileParam[0] + "-" + MobileParam[1];
        }
        if (
          MobileParam &&
          MobileParam.length > 1 &&
          MobileParam[2].length > 0
        ) {
          l_SelectedMobile = MobileParam[2];
        }
      }
    } else {
      if (props.SelectedMobileNumber.includes("+44")) {
        l_SelectedMobile = props.SelectedMobileNumber.replace("+44", "");
      } else {
        l_SelectedMobile = props.SelectedMobileNumber;
      }
    }
    setSelectedCounrtyCode(l_SelectedCounrtyCode);
    setSelectedMobile(l_SelectedMobile);
  }, [props.SelectedMobileNumber]);

  function getFlagAgainstsCountryCode(counrtyCode: string) {
    let flag = CountryCode.CountryCodeAndOtherData.filter(
      (o: any) => o.dial_code === counrtyCode
    );

    let code = "uk";
    if (flag[0]) {
      code = flag[0].code.toLowerCase();
    }

    return "/flags/" + code + "_16.png";
  }

  useEffect(() => {
    if (searchCountry !== "") {
      filterByKeyUp();
    } else {
      setCountryCode(CountryCode.CountryCodeAndOtherData);
    }
  }, [searchCountry]);

  useEffect(() => {
    props.SetSelectedMobileNumber(SelectedCounrtyCode + "-" + SelectedMobile);
  }, [SelectedCounrtyCode]);

  function filterByKeyUp() {
    let filterArray = filterByValue(
      CountryCode.CountryCodeAndOtherData,
      searchCountry
    );
    setCountryCode(filterArray);
  }

  function filterByValue(array: any, value: string) {
    return array.filter(
      (data: any) =>
        JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  function checkMobileNumberValidation() {
    if (
      props.SelectedMobileNumber.length > 0 &&
      props.SelectedMobileNumber.includes("-") &&
      props.SelectedMobileNumber.includes("+44")
    ) {
      var MobileParam = props.SelectedMobileNumber.split("-");
      if (MobileParam && MobileParam.length > 1 && MobileParam[1].length > 0) {
        if (MobileParam[1].length != 10) {
          alert("Please enter valid mobile number.");
        }
      }
    }
  }
  return (
    <>
      <div className="field-input field-input-mobile p-relative flex-da">
        <div className="country-code flex-da">
          <span onClick={() => setShowDropDown(!showDropDown)}>
            {SelectedCounrtyCode}
          </span>
          <img
            onClick={() => setShowDropDown(!showDropDown)}
            src={getFlagAgainstsCountryCode(SelectedCounrtyCode)}
            alt="Flag"
            width="20"
            height="20"
          />
          <img
            onClick={() => setShowDropDown(!showDropDown)}
            src="https://dft8zj8i6wy63.cloudfront.net/mnv/images/chev-down.svg"
            alt="chev-down"
            width="9"
            height="13"
          />
          {showDropDown && (
            <div className="country-drop">
              <input
                type="text"
                className="input-field"
                placeholder="Search Country"
                onChange={(e) => setSearchCountry(e.target.value)}
              />
              <div className="country-scroll custom-scroll">
                {countryCode &&
                  countryCode.map((item: any) => (
                    <span
                      key={"code" + item.code}
                      onClick={(e) => {
                        setSelectedCounrtyCode(item.dial_code);
                        setShowDropDown(false);
                        setSearchCountry("");
                      }}
                    >
                      {item.dial_code + " "}
                      <img
                        src={"/flags/" + item.code.toLowerCase() + "_16.png"}
                        width="16"
                        height="16"
                        alt={item.code}
                      />
                    </span>
                  ))}
              </div>
            </div>
          )}
          {/* <select
            value={SelectedCounrtyCode}
            onChange={(e) => {
              setSelectedCounrtyCode(e.target.value);
              props.SetSelectedMobileNumber(
                e.target.value + "-" + SelectedMobile
              );
            }}
          >
            {CountryCode.CountryCodeAndOtherData &&
              CountryCode.CountryCodeAndOtherData.map((item: any) => (
                <option key={"code" + item.code} value={item.dial_code}>
                  {item.dial_code + " " + item.emoji}
                </option>
              ))}
          </select> */}
        </div>
        <input
          type="number"
          className="input-field"
          placeholder="Your number"
          value={SelectedMobile}
          onFocus={() => setShowDropDown(false)}
          onBlur={() => checkMobileNumberValidation()}
          onChange={(e) => {
            setSelectedMobile(e.target.value);
            props.SetSelectedMobileNumber(
              SelectedCounrtyCode + "-" + e.target.value
            );
          }}
        />
      </div>
    </>
  );
}

//Add below code for mobile input functionality

// const [SelectedMobileNumber, SetSelectedMobileNumber] = useState("+92-3134340171");

// useEffect(() => {

//   setcontactObject((prevState: any) => ({
//     ...prevState,
//     MobileNumber: SelectedMobileNumber,
//   }))

//   console.log(SelectedMobileNumber)
// }, [SelectedMobileNumber]);
