import React, { useState, useEffect } from "react";
import AppContext from "../Context/Context";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import axiosAuth from "../Axios/Interceptors";
import moment from "moment";
import { getEndOfMonth, getStartOfMonth } from "../Shared/Utility";
import ConfirmationModal from "../App/ConfirmationModal";
import { useGlobalState } from "../Context/GlobalStateContext";
import DragDrop from "../DragDrop/DragDrop";
function EmployeeBonusAndDeductions() {
  const globalState = useGlobalState();
  const [isAnimation, setIsAnimation] = useState(false);
  const [isAddNew, setIsAddNew] = useState(0);

  const [moversList, setMoversList] = useState<any>([]);
  const [dataTypesList, setDataTypesList] = useState<any>([
    { PID: 1, Name: "Bonus" },
    { PID: 2, Name: "Deduction" },
  ]);
  const [employeeBonusAndDeductionsData, setEmployeeBonusAndDeductionsData] =
    useState<any>([]);

  const [jobReviewObject, setJobReviewObject] = useState({
    mnvmoverid: 0,
    startdate: getStartOfMonth(),
    enddate: getEndOfMonth(),
  });

  const [addEditObject, setAddEditObject] = useState({
    MNVMoverBonusAndDeductionID: 0,
    MNVMoverID: 0,
    DataType: 0,
    DataDate: new Date(),
    Amount: 0,
    Reason: "",
  });

  useEffect(() => {
    setJobReviewObject((prevState: any) => ({
      ...prevState,
      startdate: getStartOfMonth(),
      enddate: getEndOfMonth(),
    }));
    getJobReviewGetJobDetailsAndMoversList(1);
    MNVMoverBonusAndDeductionGetHistoricalData();
  }, []);

  function MNVMoverBonusAndDeductionGetHistoricalData() {
    setIsAnimation(true);
    let object = {} as any;
    object.mnvmoverid = jobReviewObject.mnvmoverid;
    object.startdate = jobReviewObject.startdate;
    object.enddate = jobReviewObject.enddate;
    axiosAuth
      .post(
        AppContext.APIURL +
          "api/MNVMoverBonusAndDeduction/MNVMoverBonusAndDeductionGetHistoricalData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_oResponse = response.data.Data;
          setEmployeeBonusAndDeductionsData(l_oResponse[0]);
        }
        setIsAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setIsAnimation(false);
      });
  }

  function getJobReviewGetJobDetailsAndMoversList(typeid: any) {
    setIsAnimation(true);
    let object = {} as any;
    object.typeid = typeid;
    // object.jobid = addEditObject.jobid;
    axiosAuth
      .post(
        AppContext.APIURL +
          "api/MNVJobReview/MNVJobReviewGetJobDetailsAndMoversList",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_oResponse = response.data.Data;
          if (typeid == 1) {
            let filtered = l_oResponse[1].filter(
              (item: any) => item.IsContractor == false
            );
            setMoversList(filtered);
          }
        }
        setIsAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setIsAnimation(false);
      });
  }

  function checkValidation() {
    MNVMoverBonusAndDeductionSaveCustom();
  }

  function MNVMoverBonusAndDeductionSaveCustom() {
    setIsAnimation(true);
    let object = {} as any;
    object.mnvmoverbonusanddeductionid =
      addEditObject.MNVMoverBonusAndDeductionID;
    object.mnvmoverid = addEditObject.MNVMoverID;
    object.datatype = addEditObject.DataType;
    object.datadate = addEditObject.DataDate;
    object.amount = addEditObject.Amount;
    object.reason = addEditObject.Reason;

    axiosAuth
      .post(
        AppContext.APIURL +
          "api/MNVMoverBonusAndDeduction/MNVMoverBonusAndDeductionSaveCustom",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data;
          setIsAddNew(0);
          MNVMoverBonusAndDeductionGetHistoricalData();
        }
        setIsAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setIsAnimation(false);
      });
  }

  function deleteMoverBonusAndDeductionRecord(
    MNVMoverBonusAndDeductionID: any
  ) {
    setIsAnimation(true);
    let object = {} as any;
    object.mnvmoverbonusanddeductionid = MNVMoverBonusAndDeductionID;
    axiosAuth
      .post(
        AppContext.APIURL +
          "api/MNVMoverBonusAndDeduction/MNVMoverBonusAndDeductionDeleteRecordCustom",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data;
          MNVMoverBonusAndDeductionGetHistoricalData();
        }
        setIsAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setIsAnimation(false);
      });
  }

  function ConfirmationModalCallBack(obj: any) {
    if (obj.ID === 1) {
      if (obj.isConfirm === true) {
        deleteMoverBonusAndDeductionRecord(obj.SelectedID);
      }
    }
    globalState.setConfirmationModalObject({
      ShowPopup: false,
      Heading: "",
      Message: "",
      ID: 0,
      SelectedID: 0,
      OtherID: 0,
    });
  }

  //Dragdrop starts
  const [dragDropFeatures, setDragDropFeatures] = useState({
    ObjectID: AppContext.MNVQuoteID,
    ObjectType: AppContext.FileUploadObjectTypeQuote,
    MaxAllowedFiles: 5,
    UploadType: 1,
    FolderName: "Quote",
    PTUploadedFiles: [],
  });

  const [uploadFileList, setUploadFileList] = useState([]);
  const [triggerChild, setTriggerChild] = useState(false);
  const [showDragdrop, setShowDragdrop] = useState(false);

  function handleDragDropCallback(ChildData: any) {
    setUploadFileList(ChildData);
    SaveFiles(ChildData);
  }

  function SaveFiles(ChildData: any) {
    // call this function for saving attachments, when you get this function responce call actuall function who save data in table.
    let uploadFileList = ChildData;
    if (uploadFileList && uploadFileList.length > 0) {
      let object = {} as any;
      object.typeid = 1;
      object.attachmenttype = dragDropFeatures.ObjectType;
      object.objectid = dragDropFeatures.ObjectID;
      object.applicationid = 1;
      object.filesdata = uploadFileList;
      axiosAuth
        .post(AppContext.FilesAPI + "api/PTAttachment/SaveFilesData", object)
        .then((response) => {
          if (response.data.Status === 1) {
            setTriggerChild(!triggerChild);
            let l_response = response.data.Data;
            saveAttachmentDataForObjectTypeIDObjectID(l_response); // call actuall function who save data in table
          } else {
            AppContext.ShowNotification("error", response.data.Message);
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else saveAttachmentDataForObjectTypeIDObjectID([]);
  }

  //Dragdrop ends

  function saveAttachmentDataForObjectTypeIDObjectID(uploadedFileList: any) {
    let object = {} as any;
    object.objecttype = dragDropFeatures.ObjectType;
    object.objectid = dragDropFeatures.ObjectID;
    object.filesdata = uploadedFileList;
    axiosAuth
      .post(
        AppContext.APIURL +
          "api/PTAttachment/SaveAttachmentDataForObjectTypeIDObjectID",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          //console.log("log", response.data.Message);
        } else {
          console.error("error", response.data.Message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <div className="inline-block-d width-100 p-all10">
      {isAnimation && <LoadingAnimation />}
      <ConfirmationModal
        ConfirmationObject={globalState.ConfirmationModalObject}
        ConfirmationModalCallBack={ConfirmationModalCallBack}
      />

      {isAddNew == 0 && (
        <>
          <>
            <div className="flex-d justify-btw mb-10">
              <div className="flex-da gap-10">
                <div className="field-input">
                  <select
                    className="input-field"
                    value={jobReviewObject.mnvmoverid}
                    onChange={(e) =>
                      setJobReviewObject((prevState: any) => ({
                        ...prevState,
                        mnvmoverid: e.target.value,
                      }))
                    }
                  >
                    <option value={0}>Select Mover</option>
                    {moversList &&
                      moversList.map((item: any, index: any) => (
                        <React.Fragment key={index}>
                          <option value={item.MNVMoverID}>{item.Name}</option>
                        </React.Fragment>
                      ))}
                  </select>
                </div>
                <div className="field-input">
                  <input
                    className="input-field"
                    type="date"
                    placeholder="Lag"
                    value={jobReviewObject.startdate}
                    onChange={(e) =>
                      setJobReviewObject((prevState: any) => ({
                        ...prevState,
                        startdate: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="field-input">
                  <input
                    className="input-field"
                    type="date"
                    placeholder="Lag"
                    value={jobReviewObject.enddate}
                    onChange={(e) =>
                      setJobReviewObject((prevState: any) => ({
                        ...prevState,
                        enddate: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="flex-daj btn-start">
                  <a
                    className="btn-primary flex-daj"
                    onClick={() => MNVMoverBonusAndDeductionGetHistoricalData()}
                  >
                    Filter
                  </a>
                </div>
              </div>
              <div className="flex-daj btn-start">
                <a
                  className="btn-primary flex-daj"
                  onClick={() => {
                    setAddEditObject({
                      MNVMoverBonusAndDeductionID: 0,
                      MNVMoverID: 0,
                      DataType: 0,
                      DataDate: new Date(),
                      Amount: 0,
                      Reason: "",
                    });

                    setIsAddNew(1);
                  }}
                >
                  Add New
                </a>
              </div>
            </div>
            <table className="reuse-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Employee</th>
                  <th>Data Type</th>
                  <th>Amount</th>
                  <th>More Information</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {employeeBonusAndDeductionsData &&
                  employeeBonusAndDeductionsData.map(
                    (item: any, index: any) => (
                      <React.Fragment key={index}>
                        <tr key={index}>
                          <td>{moment(item.DataDate).format("DD/MM/YYYY")}</td>
                          <td>{item.MNVMoverName}</td>
                          <td>{item.DataTypeName}</td>
                          <td>
                            £
                            {item.Amount?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td>{item.Reason}</td>
                          <td>
                            <div className="flex-daj gap-10">
                              <a
                                onClick={() => {
                                  setAddEditObject({
                                    MNVMoverBonusAndDeductionID:
                                      item.MNVMoverBonusAndDeductionID,
                                    MNVMoverID: item.MNVMoverID,
                                    DataType: item.DataType,
                                    DataDate: item.DataDate,
                                    Amount: item.Amount,
                                    Reason: item.Reason,
                                  });
                                  setIsAddNew(2);
                                }}
                              >
                                <img
                                  src="images/edit.svg"
                                  width="18px"
                                  height="18px"
                                  alt="Edit"
                                />
                              </a>
                              <a
                                onClick={() =>
                                  globalState.setConfirmationModalObject({
                                    ShowPopup: true,
                                    Heading: "Alert",
                                    Message:
                                      "Are your sure, you want to delete this record?",
                                    ID: 1,
                                    SelectedID:
                                      item.MNVMoverBonusAndDeductionID,
                                    OtherID: 0,
                                  })
                                }
                              >
                                <img
                                  src="images/delete.png"
                                  width="18px"
                                  height="18px"
                                  alt="Delete"
                                />
                              </a>
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    )
                  )}
              </tbody>
            </table>
          </>
        </>
      )}

      {(isAddNew == 1 || isAddNew == 2) && (
        <div className="inline-block-d text-center width-100 reuse-border p-all20">
          <>
            <div className="grid-d fr-111 gap-20">
              <div className="field-input grid-d">
                <select
                  className="input-field w-100"
                  value={addEditObject.DataType}
                  onChange={(e) =>
                    setAddEditObject((prevState: any) => ({
                      ...prevState,
                      DataType: e.target.value,
                    }))
                  }
                >
                  <option value={0}>Select Type</option>
                  {dataTypesList &&
                    dataTypesList.map((item: any, index: any) => (
                      <React.Fragment key={"Type" + index}>
                        <option value={item.PID}>{item.Name}</option>
                      </React.Fragment>
                    ))}
                </select>
              </div>
              <div className="field-input grid-d">
                <select
                  className="input-field w-100"
                  value={addEditObject.MNVMoverID}
                  onChange={(e) =>
                    setAddEditObject((prevState: any) => ({
                      ...prevState,
                      MNVMoverID: e.target.value,
                    }))
                  }
                >
                  <option value={0}>Select Mover</option>
                  {moversList &&
                    moversList.map((item: any, index: any) => (
                      <React.Fragment key={"Mover" + index}>
                        <option value={item.MNVMoverID}>{item.Name}</option>
                      </React.Fragment>
                    ))}
                </select>
              </div>
              <div className="field-input grid-d">
                <input
                  className="input-field w-100"
                  placeholder="Date"
                  type="date"
                  value={moment(addEditObject.DataDate).format("YYYY-MM-DD")}
                  onChange={(e) =>
                    setAddEditObject((prevState: any) => ({
                      ...prevState,
                      DataDate: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="field-input grid-d">
                <input
                  className="input-field w-100"
                  placeholder="Amount"
                  type="number"
                  value={addEditObject.Amount}
                  onChange={(e) =>
                    setAddEditObject((prevState: any) => ({
                      ...prevState,
                      Amount: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="field-input grid-d g-c-fw">
                <textarea
                  className="input-field w-100 area-default"
                  placeholder="More Information"
                  value={addEditObject.Reason}
                  onChange={(e) =>
                    setAddEditObject((prevState: any) => ({
                      ...prevState,
                      Reason: e.target.value,
                    }))
                  }
                ></textarea>
              </div>
              {/* <div className="field-input grid-d g-c-fw">
                <DragDrop
                  ParentFeatures={dragDropFeatures}
                  DragDropCallback={handleDragDropCallback}
                  TriggerChild={triggerChild}
                />
              </div> */}
            </div>
            <div className="flex-daj gap-10 mt-20">
              <div className="flex-daj btn-start">
                <a
                  className="btn-primary flex-daj"
                  onClick={() => checkValidation()}
                >
                  {isAddNew == 1 ? "Save" : "Update"}
                </a>
              </div>
              <div className="flex-daj btn-start">
                <a
                  className="btn-primary flex-daj"
                  onClick={() => setIsAddNew(0)}
                >
                  Cancel
                </a>
              </div>
            </div>
          </>
        </div>
      )}
    </div>
  );
}

export default EmployeeBonusAndDeductions;
