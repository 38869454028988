import React, { useState, useEffect } from "react";
import AppContext from "../Context/Context";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import axiosAuth from "../Axios/Interceptors";
import moment from "moment";
import {
  getCurrentMonthNumber,
  getEndOfMonth,
  getStartOfMonth,
} from "../Shared/Utility";
import ConfirmationModal from "../App/ConfirmationModal";
import { useGlobalState } from "../Context/GlobalStateContext";
import DragDrop from "../DragDrop/DragDrop";
function EmployeeSummary() {
  const globalState = useGlobalState();
  const [isAnimation, setIsAnimation] = useState(false);
  const [expandAll, setExpandAll] = useState(true);
  const [moversList, setMoversList] = useState<any>([]);
  const [employeeMonthlySummaryData, setEmployeeMonthlySummaryData] =
    useState<any>([]);

  const [
    preProcessedEmployeeMonthlySummaryData,
    setPreProcessedEmployeeMonthlySummaryData,
  ] = useState<any>([]);

  const [filterData, setFilterData] = useState({
    NumericYear: new Date().getFullYear(),
    NumericMonth: new Date().getMonth() + 1,
    MNVMoverID: 0,
    ShowJobData: true,
    ShowBonusAndDeductionsData: false,
    ShowReviewsData: false,
    IsFirstTime: true,
  });

  useEffect(() => {
    getJobReviewGetJobDetailsAndMoversList(1);
  }, []);

  useEffect(() => {
    if (filterData.IsFirstTime) {
      setFilterData((prevState: any) => ({
        ...prevState,
        IsFirstTime: false,
      }));
      MNVMoverJobDataGetEmployeeMonthlySummaryData();
    }
  }, [filterData]);

  useEffect(() => {
    preProcessData();
  }, [moversList, employeeMonthlySummaryData]);

  function getJobReviewGetJobDetailsAndMoversList(typeid: any) {
    setIsAnimation(true);
    let object = {} as any;
    object.typeid = typeid;
    axiosAuth
      .post(
        AppContext.APIURL +
          "api/MNVJobReview/MNVJobReviewGetJobDetailsAndMoversList",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_oResponse = response.data.Data;
          console.log("MoversList", l_oResponse);
          if (typeid == 1) {
            let filtered = l_oResponse[1].filter(
              (item: any) => item.IsContractor == false
            );
            setMoversList(filtered);
            if (filtered.length > 0) {
              setFilterData((prevState: any) => ({
                ...prevState,
                MNVMoverID: filtered[0]["MNVMoverID"],
              }));
            }
          }
        }
        setIsAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setIsAnimation(false);
      });
  }

  function MNVMoverJobDataGetEmployeeMonthlySummaryData() {
    setIsAnimation(true);
    let object = {} as any;
    object.mnvmoverid = filterData.MNVMoverID;
    object.numericyear = filterData.NumericYear;
    object.numericmonth = filterData.NumericMonth;
    axiosAuth
      .post(
        AppContext.APIURL +
          "api/MNVMoverJobData/MNVMoverJobDataGetEmployeeMonthlySummaryData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_oResponse = response.data.Data;
          console.log("EmployeeMonthlySummaryData", l_oResponse);
          setEmployeeMonthlySummaryData(l_oResponse[0]);
        }
        setIsAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setIsAnimation(false);
      });
  }

  function preProcessData() {
    let monthsList = [...AppContext.monthsList];

    for (let i = 0; i < monthsList.length; i++) {
      monthsList[i].MonthlySummaryData = [] as any;
      let currentMonth = getCurrentMonthNumber();
      if (currentMonth == monthsList[i].ID) {
        monthsList[i].ShowChild = true;
      } else {
        monthsList[i].ShowChild = false;
      }

      for (let j = 0; j < moversList.length; j++) {
        let obj = {} as any;
        obj.MNVMoverID = moversList[j].MNVMoverID;
        obj.MoverName = moversList[j].Name;

        let filtered = employeeMonthlySummaryData.filter(
          (item: any) =>
            item.MNVMoverID == moversList[j].MNVMoverID &&
            item.NumericMonth == monthsList[i].ID
        );
        if (filtered.length > 0) {
          obj.Bonus = filtered[0].Bonus;
          obj.Deductions = filtered[0].Deductions;
          obj.GrossTotal = filtered[0].GrossTotal;
          obj.NumericMonth = filtered[0].NumericMonth;
          obj.ReviewBonus = filtered[0].ReviewBonus;
          obj.Stair = filtered[0].Stair;
          obj.SubTotal = filtered[0].SubTotal;
          obj.TotalHours = filtered[0].TotalHours;
        }

        monthsList[i].MonthlySummaryData.push(obj);
      }
    }

    setPreProcessedEmployeeMonthlySummaryData(monthsList);
  }

  function showHideChild(index: any) {
    let data = [...preProcessedEmployeeMonthlySummaryData];
    data[index].ShowChild = !data[index].ShowChild;
    setPreProcessedEmployeeMonthlySummaryData(data);
  }

  function expandOrCollapseAll(isExpand: boolean) {
    setExpandAll(!isExpand);
    let data = [...preProcessedEmployeeMonthlySummaryData];
    if (isExpand) {
      for (let index = 0; index < data.length; index++) {
        data[index].ShowChild = true;
      }
    } else {
      for (let index = 0; index < data.length; index++) {
        data[index].ShowChild = false;
      }
    }

    setPreProcessedEmployeeMonthlySummaryData(data);
  }

  return (
    <div className="inline-block-d width-100 p-all20">
      {isAnimation && <LoadingAnimation />}
      <>
        <div className="flex-d justify-btw mb-10">
          <div className="flex-da gap-10">
            <div className="field-input">
              <select
                className="input-field"
                value={filterData.NumericYear}
                onChange={(e) =>
                  setFilterData((prevState: any) => ({
                    ...prevState,
                    NumericYear: e.target.value,
                  }))
                }
              >
                <option value={0}>Select Year</option>
                {AppContext.yearsList &&
                  AppContext.yearsList.map((item: any, index: any) => (
                    <React.Fragment key={index}>
                      <option value={item.ID}>{item.Name}</option>
                    </React.Fragment>
                  ))}
              </select>
            </div>
            {/* 
            <div className="field-input">
              <select
                className="input-field"
                value={filterData.NumericMonth}
                onChange={(e) =>
                  setFilterData((prevState: any) => ({
                    ...prevState,
                    NumericMonth: e.target.value,
                  }))
                }
              >
                <option value={0}>Select Month</option>
                {AppContext.monthsList &&
                  AppContext.monthsList.map((item: any, index: any) => (
                    <React.Fragment key={index}>
                      <option value={item.ID}>{item.Name}</option>
                    </React.Fragment>
                  ))}
              </select>
            </div>

            <div className="field-input">
              <select
                className="input-field"
                value={filterData.MNVMoverID}
                onChange={(e) =>
                  setFilterData((prevState: any) => ({
                    ...prevState,
                    MNVMoverID: e.target.value,
                  }))
                }
              >
                {moversList &&
                  moversList.map((item: any, index: any) => (
                    <React.Fragment key={index}>
                      <option value={item.MNVMoverID}>{item.Name}</option>
                    </React.Fragment>
                  ))}
              </select>
            </div> */}

            <div className="flex-daj btn-start">
              <a
                className="btn-primary flex-daj"
                onClick={() => MNVMoverJobDataGetEmployeeMonthlySummaryData()}
              >
                Filter
              </a>
            </div>
          </div>
          <div className="flex-daj btn-start">
            {expandAll == true && (
              <a
                className="btn-primary flex-daj"
                onClick={() => expandOrCollapseAll(true)}
              >
                Expand All
              </a>
            )}
            {expandAll == false && (
              <a
                className="btn-primary flex-daj"
                onClick={() => expandOrCollapseAll(false)}
              >
                Collapse All
              </a>
            )}
          </div>
        </div>
        <div>
          {preProcessedEmployeeMonthlySummaryData &&
            preProcessedEmployeeMonthlySummaryData.map(
              (item: any, index: any) => (
                <React.Fragment key={index}>
                  <div key={index}>
                    <a
                      className="accordion-top mt-10 flex-da justify-btw"
                      onClick={() => {
                        showHideChild(index);
                      }}
                    >
                      {item.Name}
                      <img
                        src="/images/chev-right-w.svg"
                        className={`name-count ${
                          item.ShowChild ? "rotate-90n" : "rotate-90"
                        }`}
                        width={9}
                        height={15}
                      />
                    </a>

                    {item.ShowChild == true && (
                      <table className="reuse-table fixed-table">
                        <thead>
                          <tr>
                            <th>Employee</th>
                            <th>Total Hours</th>
                            <th>SubTotal</th>
                            <th>Stair</th>
                            <th>Bonus</th>
                            <th>Review Bonus</th>
                            <th>Deduction</th>
                            <th>Gross Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {item.MonthlySummaryData &&
                            item.MonthlySummaryData.map(
                              (Inneritem: any, Innerindex: any) => (
                                <React.Fragment key={Innerindex}>
                                  <tr key={Innerindex}>
                                    <td>{Inneritem.MoverName}</td>
                                    <td className="text-right">
                                      {Inneritem.TotalHours?.toLocaleString(
                                        undefined,
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      )}
                                    </td>
                                    <td className="text-right">
                                      {Inneritem.SubTotal ? "£" : ""}
                                      {Inneritem.SubTotal?.toLocaleString(
                                        undefined,
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      )}
                                    </td>
                                    <td className="text-right">
                                      {Inneritem.Stair?.toLocaleString(
                                        undefined,
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      )}
                                    </td>
                                    <td className="text-right">
                                      {Inneritem.Bonus ? "£" : ""}
                                      {Inneritem.Bonus?.toLocaleString(
                                        undefined,
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      )}
                                    </td>
                                    <td className="text-right">
                                      {Inneritem.ReviewBonus ? "£" : ""}
                                      {Inneritem.ReviewBonus?.toLocaleString(
                                        undefined,
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      )}
                                    </td>
                                    <td className="text-right">
                                      {Inneritem.Deductions ? "£" : ""}
                                      {Inneritem.Deductions?.toLocaleString(
                                        undefined,
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      )}
                                    </td>
                                    <td className="text-right">
                                      {Inneritem.GrossTotal ? "£" : ""}
                                      {Inneritem.GrossTotal?.toLocaleString(
                                        undefined,
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      )}
                                    </td>
                                  </tr>
                                </React.Fragment>
                              )
                            )}
                        </tbody>
                      </table>
                    )}
                  </div>
                </React.Fragment>
              )
            )}
        </div>
      </>
    </div>
  );
}

export default EmployeeSummary;
