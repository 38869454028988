import React, { useState, useEffect } from "react";
import AppContext from "../Context/Context";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import axiosAuth from "../Axios/Interceptors";
import moment from "moment";
import { addCommas, removeNonNumeric } from "../Shared/Utility";
import InputMask from "../App/InputMask";
import LockUnlock from "../App/LockUnlock";
function EmployeeRates() {
  const [isAnimation, setIsAnimation] = useState(false);
  const [isLocked, setIsLocked] = useState(false);

  const [moversList, setMoversList] = useState<any>([]);
  const [rateTypes, setRateTypes] = useState<any>([]);
  const [ratesData, setRatesData] = useState<any>([]);

  useEffect(() => {
    MNVEmployeeRateGetData();
  }, []);

  useEffect(() => {
    formatRatesData();
  }, [ratesData]);

  function MNVEmployeeRateGetData() {
    setIsAnimation(true);
    let object = {} as any;
    axiosAuth
      .post(
        AppContext.APIURL + "api/MNVEmployeeRate/MNVEmployeeRateGetData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_oResponse = response.data.Data;
          setMoversList(l_oResponse[0]);
          setRateTypes(l_oResponse[1]);
          setRatesData(l_oResponse[2]);
        }
        setIsAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setIsAnimation(false);
      });
  }

  function MNVEmployeeRateUpdateData(MNVEmployeeRateID: number, Rate: number) {
    setIsAnimation(true);
    let object = {} as any;
    object.mnvemployeerateid = MNVEmployeeRateID;
    object.rate = Rate;
    axiosAuth
      .post(
        AppContext.APIURL + "api/MNVEmployeeRate/MNVEmployeeRateUpdateData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          let l_oResponse = response.data.Data;
          MNVEmployeeRateGetData();
        }
        setIsAnimation(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setIsAnimation(false);
      });
  }

  function formatRatesData() {
    let moversListLocal = [...moversList];
    for (let i = 0; i < moversList.length; i++) {
      let MNVMoverID = moversList[i]["MNVMoverID"];
      for (let j = 0; j < rateTypes.length; j++) {
        let MNVEmployeeRateTypeID = rateTypes[j]["MNVEmployeeRateTypeID"];

        var filtered = ratesData.filter(
          (item: any) =>
            item.MNVMoverID == MNVMoverID &&
            item.RateType == MNVEmployeeRateTypeID
        );
        if (filtered.length > 0) {
          moversListLocal[i][MNVEmployeeRateTypeID + "ID"] =
            filtered[0].MNVEmployeeRateID;
          moversListLocal[i][MNVEmployeeRateTypeID + "Rate"] = filtered[0].Rate;
        }
      }
    }
    setMoversList(moversListLocal);
  }

  function InputMaskOnChangeHandler(e: any) {
    updateValueOfInputKey(e.ColumnNames, removeNonNumeric(e.Value), e.PID);
  }

  function updateValueOfInputKey(Type: number, Value: any, PID: number) {
    let ratesDataLocal = [...ratesData];
    debugger;
    for (let i = 0; i < ratesDataLocal.length; i++) {
      if (ratesDataLocal[i].MNVEmployeeRateID == PID) {
        ratesDataLocal[i]["Rate"] = addCommas(Value);
        break;
      }
    }
    setRatesData(ratesDataLocal);
  }

  function InputMaskOnBlurHandler(e: any) {
    updateRecord(e.ColumnNames, e.Value, e.PID);
  }

  function updateRecord(ColumnNames: any, Value: any, PID: any) {
    let filtered = ratesData.filter(
      (item: any) => item.MNVEmployeeRateID == PID
    );
    if (filtered.length > 0) {
      MNVEmployeeRateUpdateData(PID, filtered[0].Rate);
    }
  }

  function setLocked(value: boolean) {
    setIsLocked(value);
  }

  return (
    <>
      {isAnimation && <LoadingAnimation />}

      <div className="inline-block-d width-100 p-all10">
        <div className="flex-d justify-end mb-10">
          <LockUnlock locked={isLocked} setLocked={setLocked}></LockUnlock>
        </div>
        <>
          <table className="reuse-table">
            <thead>
              <tr>
                <th>Mover Name</th>
                {rateTypes &&
                  rateTypes.map((item: any, index: any) => (
                    <React.Fragment key={"ratetypeh" + index}>
                      <th>{item.Name}</th>
                    </React.Fragment>
                  ))}
              </tr>
            </thead>
            <tbody>
              {moversList &&
                moversList.map((item: any, index: any) => (
                  <React.Fragment key={index}>
                    <tr key={index}>
                      <td>{item.Name}</td>
                      {rateTypes &&
                        rateTypes.map((rateType: any, rateTypeIndex: any) => (
                          <React.Fragment key={"ratetypetd" + rateTypeIndex}>
                            <td className="p-all0">
                              <InputMask
                                Disabled={isLocked}
                                Prefix={"£"}
                                Suffix={""}
                                ToFixed={2}
                                ClassName=""
                                ColumnName={
                                  item[rateType.MNVEmployeeRateTypeID + "ID"]
                                }
                                Value={
                                  item[rateType.MNVEmployeeRateTypeID + "Rate"]
                                }
                                PID={
                                  item[rateType.MNVEmployeeRateTypeID + "ID"]
                                }
                                onChangeHandler={InputMaskOnChangeHandler}
                                onBlurHandler={InputMaskOnBlurHandler}
                              />
                            </td>
                          </React.Fragment>
                        ))}
                    </tr>
                  </React.Fragment>
                ))}
            </tbody>
          </table>
        </>
      </div>
    </>
  );
}

export default EmployeeRates;
