import React from "react";
import axiosAuth from "../Axios/Interceptors";
import AppContext from "../Context/Context";
import { useState, useEffect } from "react";
import { useGlobalState } from "../Context/GlobalStateContext";
function Home() {
  const globalState = useGlobalState();
  const [dataArray, setDataArray] = useState([]);

  useEffect(() => {}, []);

  const LogOutUser = () => {
    AppContext.SetCookie("Token", "", 0);
    AppContext.SetCookie("RefreshToken", "", 0);
    window.location.href = "/login";
  };

  return <div>Home</div>;
}

export default Home;
